import { format } from "date-fns";
import dayjs from "dayjs";
import { murmurHash32 } from "../utils/murmur";
import { computeLevel, generateReport } from "./engine";
import { saveRid } from "./rid";
import { type Filters, removeLevelFunnelDimensions } from "./types";

export async function processReport(clickhouse, game_id, date_start, date_end, date_preset, filters: Filters, level, deliverable_metrics, cohort_metrics, metric_dimensions, rid) {
  let final_date_start = date_start;
  let final_date_end = date_end;
  const setDateFilter = (filters, filterStart, filterEnd) => {
    filters.filters.forEach((filter) => {
      if (filter.field === "date") {
        filter.valueBetweenStart = filterStart;
        filter.valueBetweenEnd = filterEnd;
      }
    });
  };
  switch (date_preset) {
    case "today":
      final_date_start = dayjs().format("YYYY-MM-DD");
      final_date_end = dayjs().format("YYYY-MM-DD");
      setDateFilter(filters, final_date_start, final_date_end);
      break;
    case "yesterday":
      final_date_start = dayjs().add(-1, "d").format("YYYY-MM-DD");
      final_date_end = dayjs().format("YYYY-MM-DD");
      setDateFilter(filters, final_date_start, final_date_start);
      break;
    case "before_yesterday":
      final_date_start = dayjs().add(-2, "d").format("YYYY-MM-DD");
      final_date_end = dayjs().format("YYYY-MM-DD");
      setDateFilter(filters, final_date_start, final_date_start);
      break;
    case "last_7_days":
      {
        const filter_date_start = dayjs().add(-7, "d").format("YYYY-MM-DD");
        const filter_date_end = dayjs().format("YYYY-MM-DD");
        final_date_end = dayjs().format("YYYY-MM-DD");
        setDateFilter(filters, filter_date_start, filter_date_end);
      }
      break;
    case "last_30_days":
      {
        const filter_date_start = dayjs().add(-30, "d").format("YYYY-MM-DD");
        const filter_date_end = dayjs().format("YYYY-MM-DD");
        final_date_end = dayjs().format("YYYY-MM-DD");
        setDateFilter(filters, filter_date_start, filter_date_end);
      }
      break;
    case "last_14_days":
      {
        const filter_date_start = dayjs().add(-14, "d").format("YYYY-MM-DD");
        const filter_date_end = dayjs().format("YYYY-MM-DD");
        final_date_end = dayjs().format("YYYY-MM-DD");
        setDateFilter(filters, filter_date_start, filter_date_end);
      }
      break;
    case "last_90_days":
      {
        const filter_date_start = dayjs().add(-90, "d").format("YYYY-MM-DD");
        const filter_date_end = dayjs().format("YYYY-MM-DD");
        final_date_end = dayjs().format("YYYY-MM-DD");
        setDateFilter(filters, filter_date_start, filter_date_end);
      }
      break;
  }
  const reportData: any = await generateReport(clickhouse, game_id, final_date_start, final_date_end, filters, level, deliverable_metrics, cohort_metrics, metric_dimensions);
  const accumulate: any = [];

  // if (!reportData || reportData.length === 0) {
  //   return accumulate;
  // }
  reportData.forEach((element) => {
    const row: any = {};

    Object.keys(element).forEach((key) => {
      const queryTypes = ["deliverable", "cohort", "level_funnel"];
      queryTypes.forEach((qt) => {
        if (computeLevel(qt, level, metric_dimensions).includes(key)) {
          row[key] = element[key];
        }
      });
    });

    cohort_metrics.forEach((metric) => {
      switch (metric) {
        case "retained_users":
          if (!row.cohort_periods) {
            row.cohort_periods = [];
          }

          if (typeof element.cohort_periods === "undefined") {
            return;
          }
          // Find the object with the same period

          element.cohort_periods.forEach((cohort_period) => {
            const found = row.cohort_periods.find((c) => c.period === cohort_period.period);
            if (found) {
              found.retained_users = Number(cohort_period.c_distinct_users);
            } else {
              row.cohort_periods.push({
                period: Number(cohort_period.period),
                retained_users: Number(cohort_period.c_distinct_users),
              });
            }
          });
          break;
        case "ad_revenue":
          if (!row.cohort_periods) {
            row.cohort_periods = [];
          }

          if (typeof element.cohort_periods === "undefined") {
            return;
          }
          // Find the object with the same period

          element.cohort_periods.forEach((cohort_period) => {
            const found = row.cohort_periods.find((c) => c.period === cohort_period.period);
            if (found) {
              found.ad_revenue = Number(cohort_period.c_banner_revenue) + Number(cohort_period.c_interstitial_revenue) + Number(cohort_period.c_rewarded_revenue) + Number(cohort_period.c_appopen_revenue);
            } else {
              row.cohort_periods.push({
                period: Number(cohort_period.period),
                ad_revenue: Number(cohort_period.c_banner_revenue) + Number(cohort_period.c_interstitial_revenue) + Number(cohort_period.c_rewarded_revenue) + Number(cohort_period.c_appopen_revenue),
              });
            }
          });
          break;
        case "total_revenue":
          if (!row.cohort_periods) {
            row.cohort_periods = [];
          }

          if (typeof element.cohort_periods === "undefined") {
            return;
          }
          // Find the object with the same period

          element.cohort_periods.forEach((cohort_period) => {
            const found = row.cohort_periods.find((c) => c.period === cohort_period.period);
            if (found) {
              found.total_revenue =
                Number(cohort_period.c_iap_revenue) + Number(cohort_period.c_banner_revenue) + Number(cohort_period.c_interstitial_revenue) + Number(cohort_period.c_rewarded_revenue) + Number(cohort_period.c_appopen_revenue);
            } else {
              row.cohort_periods.push({
                period: Number(cohort_period.period),
                total_revenue: Number(cohort_period.c_iap_revenue) + Number(cohort_period.c_banner_revenue) + Number(cohort_period.c_interstitial_revenue) + Number(cohort_period.c_rewarded_revenue) + Number(cohort_period.c_appopen_revenue),
              });
            }
          });
          break;
        case "iap_revenue":
          if (!row.cohort_periods) {
            row.cohort_periods = [];
          }

          if (typeof element.cohort_periods === "undefined") {
            return;
          }
          // Find the object with the same period

          element.cohort_periods.forEach((cohort_period) => {
            const found = row.cohort_periods.find((c) => c.period === cohort_period.period);
            if (found) {
              found.iap_revenue = Number(cohort_period.c_iap_revenue);
            } else {
              row.cohort_periods.push({
                period: Number(cohort_period.period),
                iap_revenue: Number(cohort_period.c_iap_revenue),
              });
            }
          });
          break;
        case "cohort_size":
          {
            const targetPeriod = 0;
            if (typeof element.cohort_periods === "undefined") {
              return;
            }

            const cohort_periods: any = [];
            const firstPeriod = element.cohort_periods.find((c) => c.period === 0);
            if (firstPeriod) {
              element.cohort_periods.forEach((cohort_period) => {
                const found = cohort_periods.find((c) => c.period === cohort_period.period);
                if (found) {
                  found.retained_users = Number(cohort_period.c_distinct_users);
                } else {
                  cohort_periods.push({
                    period: Number(cohort_period.period),
                    retained_users: Number(cohort_period.c_distinct_users),
                  });
                }
              });
              row[metric] = cohort_periods.find((c) => c.period === targetPeriod)?.retained_users ?? 0;
            } else {
              row[metric] = 0;
            }
          }
          break;
        case "d0_retention":
        case "d1_retention":
        case "d2_retention":
        case "d3_retention":
        case "d4_retention":
        case "d5_retention":
        case "d6_retention":
        case "d7_retention":
        case "d14_retention":
        case "d21_retention":
        case "d27_retention":
        case "d30_retention":
        case "d60_retention":
        case "d90_retention":
        case "d120_retention":
        case "d150_retention":
        case "d180_retention":
        case "d210_retention":
        case "d240_retention":
        case "d270_retention":
        case "d300_retention":
        case "d330_retention":
        case "d360_retention":
          {
            const targetPeriod = Number(metric.replace("d", "").split("_")[0]);
            if (typeof element.cohort_periods === "undefined") {
              return;
            }

            const cohort_periods: any = [];
            const firstPeriod = element.cohort_periods.find((c) => c.period === 0);
            if (firstPeriod) {
              element.cohort_periods.forEach((cohort_period) => {
                const found = cohort_periods.find((c) => c.period === cohort_period.period);
                if (found) {
                  found.retention = Number(cohort_period.c_distinct_users) / firstPeriod.c_distinct_users;
                } else {
                  cohort_periods.push({
                    period: Number(cohort_period.period),
                    retention: Number(cohort_period.c_distinct_users) / firstPeriod.c_distinct_users,
                  });
                }
              });
              row[metric] = cohort_periods.find((c) => c.period === targetPeriod)?.retention ?? 0;
            } else {
              row[metric] = 0;
            }
          }
          break;
        case "retention":
          {
            if (!row.cohort_periods) {
              row.cohort_periods = [];
            }

            if (typeof element.cohort_periods === "undefined") {
              return;
            }

            const firstPeriod = element.cohort_periods.find((c) => c.period === 0);
            if (firstPeriod) {
              element.cohort_periods.forEach((cohort_period) => {
                const found = row.cohort_periods.find((c) => c.period === cohort_period.period);
                if (found) {
                  found.retention = Number(cohort_period.c_distinct_users) / firstPeriod.c_distinct_users;
                } else {
                  row.cohort_periods.push({
                    period: Number(cohort_period.period),
                    retention: Number(cohort_period.c_distinct_users) / firstPeriod.c_distinct_users,
                  });
                }
              });
            }
          }
          break;
        case "cumulative_ad_revenue":
          if (!row.cohort_periods) {
            row.cohort_periods = [];
          }

          if (typeof element.cohort_periods === "undefined") {
            return;
          }

          element.cohort_periods.forEach((cohort_period) => {
            const found = row.cohort_periods.find((c) => c.period === cohort_period.period);
            if (found) {
              found.cumulative_ad_revenue = Number(cohort_period.c_banner_revenue) + Number(cohort_period.c_interstitial_revenue) + Number(cohort_period.c_rewarded_revenue) + Number(cohort_period.c_appopen_revenue);
            } else {
              row.cohort_periods.push({
                period: Number(cohort_period.period),
                cumulative_ad_revenue: Number(cohort_period.c_banner_revenue) + Number(cohort_period.c_interstitial_revenue) + Number(cohort_period.c_rewarded_revenue) + Number(cohort_period.c_appopen_revenue),
              });
            }
          });

          row.cohort_periods.sort((a, b) => a.period - b.period);

          row.cohort_periods.forEach((cohort_period, index) => {
            if (index > 0) {
              cohort_period.cumulative_ad_revenue += row.cohort_periods[index - 1].cumulative_ad_revenue;
            }
          });
          break;
        case "cumulative_iap_revenue":
          if (!row.cohort_periods) {
            row.cohort_periods = [];
          }

          if (typeof element.cohort_periods === "undefined") {
            return;
          }

          element.cohort_periods.forEach((cohort_period) => {
            const found = row.cohort_periods.find((c) => c.period === cohort_period.period);
            if (found) {
              found.cumulative_iap_revenue = Number(cohort_period.c_banner_revenue) + Number(cohort_period.c_interstitial_revenue) + Number(cohort_period.c_rewarded_revenue);
            } else {
              row.cohort_periods.push({
                period: Number(cohort_period.period),
                cumulative_iap_revenue: Number(cohort_period.c_banner_revenue) + Number(cohort_period.c_interstitial_revenue) + Number(cohort_period.c_rewarded_revenue),
              });
            }
          });

          row.cohort_periods.sort((a, b) => a.period - b.period);

          row.cohort_periods.forEach((cohort_period, index) => {
            if (index > 0) {
              cohort_period.cumulative_iap_revenue += row.cohort_periods[index - 1].cumulative_iap_revenue;
            }
          });
          break;
        case "cumulative_total_revenue":
          if (!row.cohort_periods) {
            row.cohort_periods = [];
          }

          if (typeof element.cohort_periods === "undefined") {
            return;
          }

          element.cohort_periods.forEach((cohort_period) => {
            const found = row.cohort_periods.find((c) => c.period === cohort_period.period);
            if (found) {
              found.cumulative_total_revenue =
                Number(cohort_period.c_iap_revenue) + Number(cohort_period.c_banner_revenue) + Number(cohort_period.c_interstitial_revenue) + Number(cohort_period.c_rewarded_revenue) + Number(cohort_period.c_appopen_revenue);
            } else {
              row.cohort_periods.push({
                period: Number(cohort_period.period),
                cumulative_total_revenue:
                  Number(cohort_period.c_iap_revenue) + Number(cohort_period.c_banner_revenue) + Number(cohort_period.c_interstitial_revenue) + Number(cohort_period.c_rewarded_revenue) + Number(cohort_period.c_appopen_revenue),
              });
            }
          });

          row.cohort_periods.sort((a, b) => a.period - b.period);

          row.cohort_periods.forEach((cohort_period, index) => {
            if (index > 0) {
              cohort_period.cumulative_total_revenue += row.cohort_periods[index - 1].cumulative_total_revenue;
            }
          });
          break;
        case "ad_roas":
          if (!row.cohort_periods) {
            row.cohort_periods = [];
          }

          if (typeof element.cohort_periods === "undefined") {
            return;
          }

          element.cohort_periods.forEach((cohort_period) => {
            const found = row.cohort_periods.find((c) => c.period === cohort_period.period);
            if (found) {
              found.ad_roas = Number(cohort_period.c_banner_revenue) + Number(cohort_period.c_interstitial_revenue) + Number(cohort_period.c_rewarded_revenue) + Number(cohort_period.c_appopen_revenue);
            } else {
              row.cohort_periods.push({
                period: Number(cohort_period.period),
                ad_roas: Number(cohort_period.c_banner_revenue) + Number(cohort_period.c_interstitial_revenue) + Number(cohort_period.c_rewarded_revenue) + Number(cohort_period.c_appopen_revenue),
              });
            }
          });

          row.cohort_periods.sort((a, b) => a.period - b.period);

          row.cohort_periods.forEach((cohort_period, index) => {
            if (index > 0) {
              cohort_period.ad_roas += row.cohort_periods[index - 1].ad_roas;
            }
          });

          row.cohort_periods.forEach((cohort_period) => {
            cohort_period.ad_roas /= element.spend;
          });
          break;
        case "iap_roas":
          if (!row.cohort_periods) {
            row.cohort_periods = [];
          }

          if (typeof element.cohort_periods === "undefined") {
            return;
          }

          element.cohort_periods.forEach((cohort_period) => {
            const found = row.cohort_periods.find((c) => c.period === cohort_period.period);
            if (found) {
              found.iap_roas = Number(cohort_period.c_iap_revenue);
            } else {
              row.cohort_periods.push({
                period: Number(cohort_period.period),
                iap_roas: Number(cohort_period.c_iap_revenue),
              });
            }
          });

          row.cohort_periods.sort((a, b) => a.period - b.period);

          row.cohort_periods.forEach((cohort_period, index) => {
            if (index > 0) {
              cohort_period.iap_roas += row.cohort_periods[index - 1].iap_roas;
            }
          });

          row.cohort_periods.forEach((cohort_period) => {
            cohort_period.iap_roas /= element.spend;
          });
          break;
        case "total_roas":
          if (!row.cohort_periods) {
            row.cohort_periods = [];
          }

          if (typeof element.cohort_periods === "undefined") {
            return;
          }

          element.cohort_periods.forEach((cohort_period) => {
            const found = row.cohort_periods.find((c) => c.period === cohort_period.period);
            if (found) {
              found.total_roas =
                Number(cohort_period.c_iap_revenue) + Number(cohort_period.c_banner_revenue) + Number(cohort_period.c_interstitial_revenue) + Number(cohort_period.c_rewarded_revenue) + Number(cohort_period.c_appopen_revenue);
            } else {
              row.cohort_periods.push({
                period: Number(cohort_period.period),
                total_roas: Number(cohort_period.c_iap_revenue) + Number(cohort_period.c_banner_revenue) + Number(cohort_period.c_interstitial_revenue) + Number(cohort_period.c_rewarded_revenue) + Number(cohort_period.c_appopen_revenue),
              });
            }
          });

          row.cohort_periods.sort((a, b) => a.period - b.period);

          row.cohort_periods.forEach((cohort_period, index) => {
            if (index > 0) {
              cohort_period.total_roas += row.cohort_periods[index - 1].total_roas;
            }
          });

          row.cohort_periods.forEach((cohort_period) => {
            cohort_period.total_roas /= element.spend;
          });
          break;
        case "d0_roas":
        case "d1_roas":
        case "d2_roas":
        case "d3_roas":
        case "d4_roas":
        case "d5_roas":
        case "d6_roas":
        case "d7_roas":
        case "d14_roas":
        case "d21_roas":
        case "d30_roas":
        case "d60_roas":
        case "d90_roas":
          {
            const targetPeriod = Number(metric.replace("d", "").split("_")[0]);
            if (typeof element.cohort_periods === "undefined") {
              return;
            }

            const cohort_periods: any = [];
            element.cohort_periods.forEach((cohort_period) => {
              const found = cohort_periods.find((c) => c.period === cohort_period.period);
              if (found) {
                found.total_roas =
                  Number(cohort_period.c_iap_revenue) + Number(cohort_period.c_banner_revenue) + Number(cohort_period.c_interstitial_revenue) + Number(cohort_period.c_rewarded_revenue) + Number(cohort_period.c_appopen_revenue);
              } else {
                cohort_periods.push({
                  period: Number(cohort_period.period),
                  total_roas: Number(cohort_period.c_iap_revenue) + Number(cohort_period.c_banner_revenue) + Number(cohort_period.c_interstitial_revenue) + Number(cohort_period.c_rewarded_revenue) + Number(cohort_period.c_appopen_revenue),
                });
              }
            });
            cohort_periods.sort((a, b) => a.period - b.period);

            cohort_periods.forEach((cohort_period, index) => {
              if (index > 0) {
                cohort_period.total_roas += cohort_periods[index - 1].total_roas;
              }
            });

            cohort_periods.forEach((cohort_period) => {
              cohort_period.total_roas /= element.spend;
            });
            row[metric] = cohort_periods.find((c) => c.period === targetPeriod)?.total_roas ?? 0;
          }
          break;
        case "d0_banner_cpm":
        case "d1_banner_cpm":
        case "d7_banner_cpm":
        case "d0_interstitial_cpm":
        case "d1_interstitial_cpm":
        case "d7_interstitial_cpm":
        case "d0_rewarded_cpm":
        case "d1_rewarded_cpm":
        case "d7_rewarded_cpm":
          {
            const targetPeriod = Number(metric.replace("d", "").split("_")[0]);
            if (typeof element.cohort_periods === "undefined") {
              return;
            }

            const targetAdType = metric.split("_")[1];

            const sourceKeyImp = `c_${targetAdType}_impressions`;
            const sourceKeyRev = `c_${targetAdType}_revenue`;
            const targetKey = metric;

            const cohort_periods: any = [];
            element.cohort_periods.forEach((cohort_period) => {
              const found = cohort_periods.find((c) => c.period === cohort_period.period);
              if (found) {
                found[targetKey] = (Number(cohort_period[sourceKeyRev]) / Number(cohort_period[sourceKeyImp])) * 1000;
              } else {
                cohort_periods.push({
                  period: Number(cohort_period.period),
                  [targetKey]: (Number(cohort_period[sourceKeyRev]) / Number(cohort_period[sourceKeyImp])) * 1000,
                });
              }
            });
            cohort_periods.sort((a, b) => a.period - b.period);
            row[metric] = cohort_periods.find((c) => c.period === targetPeriod)?.[targetKey] ?? 0;
          }
          break;
        case "banner_impressions_per_user":
        case "interstitial_impressions_per_user":
        case "rewarded_impressions_per_user":
        case "appopen_impressions_per_user":
          {
            if (!row.cohort_periods) {
              row.cohort_periods = [];
            }

            const targetAdType = `${metric.split("_")[0]}_impressions`;

            if (typeof element.cohort_periods === "undefined") {
              return;
            }
            const firstPeriod = element.cohort_periods.find((c) => c.period === 0);
            if (firstPeriod) {
              element.cohort_periods.forEach((cohort_period) => {
                const found = row.cohort_periods.find((c) => c.period === cohort_period.period);
                if (found) {
                  found[metric] = Number(cohort_period[`c_${targetAdType}`]) / firstPeriod.c_distinct_users;
                } else {
                  row.cohort_periods.push({
                    period: Number(cohort_period.period),
                    [metric]: Number(cohort_period[`c_${targetAdType}`]) / firstPeriod.c_distinct_users,
                  });
                }
              });
            }
          }
          break;
        case "d0_banner_impressions_per_user":
        case "d1_banner_impressions_per_user":
        case "d7_banner_impressions_per_user":
        case "d0_interstitial_impressions_per_user":
        case "d1_interstitial_impressions_per_user":
        case "d7_interstitial_impressions_per_user":
        case "d14_interstitial_impressions_per_user":
        case "d21_interstitial_impressions_per_user":
        case "d30_interstitial_impressions_per_user":
        case "d0_rewarded_impressions_per_user":
        case "d1_rewarded_impressions_per_user":
        case "d7_rewarded_impressions_per_user":
          {
            const targetPeriod = Number(metric.replace("d", "").split("_")[0]);
            if (typeof element.cohort_periods === "undefined") {
              return;
            }

            const targetAdType = metric.split("_")[1];

            const sourceKey = `c_${targetAdType}_impressions`;
            const targetKey = metric;

            const cohort_periods: any = [];
            const firstPeriod = element.cohort_periods.find((c) => c.period === 0);
            if (firstPeriod) {
              element.cohort_periods.forEach((cohort_period) => {
                const found = cohort_periods.find((c) => c.period === cohort_period.period);
                if (found) {
                  found[targetKey] = Number(cohort_period[sourceKey]);
                } else {
                  cohort_periods.push({
                    period: Number(cohort_period.period),
                    [targetKey]: Number(cohort_period[sourceKey]),
                  });
                }
              });
              cohort_periods.sort((a, b) => a.period - b.period);

              cohort_periods.forEach((cohort_period, index) => {
                if (index > 0) {
                  cohort_period[targetKey] += cohort_periods[index - 1][targetKey];
                }
              });

              cohort_periods.forEach((cohort_period) => {
                cohort_period[targetKey] /= firstPeriod.c_distinct_users;
              });
              row[metric] = cohort_periods.find((c) => c.period === targetPeriod)?.[targetKey] ?? 0;
            }
          }
          break;
        case "roas_mult_7_0":
        case "roas_mult_30_0":
        case "roas_mult_60_0":
        case "roas_mult_90_0":
        case "roas_mult_14_7":
        case "roas_mult_30_14":
        case "roas_mult_30_7":
        case "roas_mult_60_7":
        case "roas_mult_90_7":
        case "roas_mult_60_30":
        case "roas_mult_90_30":
        case "roas_mult_90_60":
        case "roas_mult_120_30":
        case "roas_mult_120_60":
        case "roas_mult_120_90":
        case "roas_mult_150_120":
        case "roas_mult_180_150":
        case "roas_mult_210_180":
        case "roas_mult_240_210":
        case "roas_mult_270_240":
        case "roas_mult_300_270":
        case "roas_mult_330_300":
        case "roas_mult_360_330":
        case "roas_mult_360_180":
        case "roas_mult_360_30":
        case "roas_mult_x_0":
          {
            if (typeof element.cohort_periods === "undefined") {
              return;
            }

            element.cohort_periods.sort((a, b) => a.period - b.period);
            const maxPeriod = element.cohort_periods[element.cohort_periods.length - 1].period;

            let targetPeriod1 = 0;
            const targetPeriod2 = Number(metric.split("_")[3]);

            const firstPart = metric.split("_")[2];
            if (firstPart === "x") {
              targetPeriod1 = maxPeriod;
            } else {
              targetPeriod1 = Number(firstPart);
            }

            const cohort_periods: any = [];
            element.cohort_periods.forEach((cohort_period) => {
              const found = cohort_periods.find((c) => c.period === cohort_period.period);
              if (found) {
                found.total_roas =
                  Number(cohort_period.c_iap_revenue) + Number(cohort_period.c_banner_revenue) + Number(cohort_period.c_interstitial_revenue) + Number(cohort_period.c_rewarded_revenue) + Number(cohort_period.c_appopen_revenue);
              } else {
                cohort_periods.push({
                  period: Number(cohort_period.period),
                  total_roas: Number(cohort_period.c_iap_revenue) + Number(cohort_period.c_banner_revenue) + Number(cohort_period.c_interstitial_revenue) + Number(cohort_period.c_rewarded_revenue) + Number(cohort_period.c_appopen_revenue),
                });
              }
            });

            cohort_periods.sort((a, b) => a.period - b.period);

            cohort_periods.forEach((cohort_period, index) => {
              if (index > 0) {
                cohort_period.total_roas += cohort_periods[index - 1].total_roas;
              }
            });

            cohort_periods.forEach((cohort_period) => {
              cohort_period.total_roas /= element.spend;
            });
            row[metric] = 0;

            const roas1 = cohort_periods.find((c) => c.period === targetPeriod1)?.total_roas ?? 0;
            const roas2 = cohort_periods.find((c) => c.period === targetPeriod2)?.total_roas ?? 0;
            if (roas2 > 0) {
              row[metric] = roas1 / roas2;
            }
          }
          break;
        case "current_roas":
          {
            if (typeof element.cohort_periods === "undefined") {
              return;
            }
            element.cohort_periods.sort((a, b) => a.period - b.period);
            const maxPeriod = element.cohort_periods[element.cohort_periods.length - 1].period;

            const cohort_periods: any = [];
            element.cohort_periods.forEach((cohort_period) => {
              const found = cohort_periods.find((c) => c.period === cohort_period.period);
              if (found) {
                found.total_roas =
                  Number(cohort_period.c_iap_revenue) + Number(cohort_period.c_banner_revenue) + Number(cohort_period.c_interstitial_revenue) + Number(cohort_period.c_rewarded_revenue) + Number(cohort_period.c_appopen_revenue);
              } else {
                cohort_periods.push({
                  period: Number(cohort_period.period),
                  total_roas: Number(cohort_period.c_iap_revenue) + Number(cohort_period.c_banner_revenue) + Number(cohort_period.c_interstitial_revenue) + Number(cohort_period.c_rewarded_revenue) + Number(cohort_period.c_appopen_revenue),
                });
              }
            });

            cohort_periods.sort((a, b) => a.period - b.period);

            cohort_periods.forEach((cohort_period, index) => {
              if (index > 0) {
                cohort_period.total_roas += cohort_periods[index - 1].total_roas;
              }
            });

            cohort_periods.forEach((cohort_period) => {
              cohort_period.total_roas /= element.spend;
            });
            row[metric] = cohort_periods.find((c) => c.period === maxPeriod)?.total_roas ?? 0;
          }
          break;

        case "time_spent_per_user":
          {
            if (!row.cohort_periods) {
              row.cohort_periods = [];
            }

            if (typeof element.cohort_periods === "undefined") {
              return;
            }

            const firstPeriod = element.cohort_periods.find((c) => c.period === 0);
            if (firstPeriod) {
              element.cohort_periods.forEach((cohort_period) => {
                const found = row.cohort_periods.find((c) => c.period === cohort_period.period);
                if (found) {
                  found.time_spent_per_user = Number(cohort_period.c_time_spent) / firstPeriod.c_distinct_users;
                } else {
                  row.cohort_periods.push({
                    period: Number(cohort_period.period),
                    time_spent_per_user: Number(cohort_period.c_time_spent) / firstPeriod.c_distinct_users,
                  });
                }
              });
            }
          }
          break;
        case "cumulative_time_spent_per_user":
          {
            if (!row.cohort_periods) {
              row.cohort_periods = [];
            }

            if (typeof element.cohort_periods === "undefined") {
              return;
            }

            const firstPeriod = element.cohort_periods.find((c) => c.period === 0);
            if (firstPeriod) {
              element.cohort_periods.forEach((cohort_period) => {
                if (cohort_period.period < 0) {
                  return;
                }
                const found = row.cohort_periods.find((c) => c.period === cohort_period.period);
                if (found) {
                  found.cumulative_time_spent_per_user = Number(cohort_period.c_time_spent);
                } else {
                  row.cohort_periods.push({
                    period: Number(cohort_period.period),
                    cumulative_time_spent_per_user: Number(cohort_period.c_time_spent),
                  });
                }
              });

              row.cohort_periods.sort((a, b) => a.period - b.period);

              row.cohort_periods.forEach((cohort_period, index) => {
                if (index > 0) {
                  cohort_period.cumulative_time_spent_per_user += row.cohort_periods[index - 1].cumulative_time_spent_per_user;
                }
              });
              row.cohort_periods.forEach((cohort_period, index) => {
                cohort_period.cumulative_time_spent_per_user = cohort_period.cumulative_time_spent_per_user / firstPeriod.c_distinct_users;
              });
            }
          }
          break;

        case "d0_cumulative_time_spent_per_user":
        case "d1_cumulative_time_spent_per_user":
        case "d7_cumulative_time_spent_per_user":
          {
            if (!row.cohort_periods) {
              row.cohort_periods = [];
            }

            if (typeof element.cohort_periods === "undefined") {
              return;
            }

            const targetPeriod = Number(metric.replace("d", "").split("_")[0]);

            const cohort_periods: any = [];
            const firstPeriod = element.cohort_periods.find((c) => c.period === 0);
            if (firstPeriod) {
              element.cohort_periods.forEach((cohort_period) => {
                const found = cohort_periods.find((c) => c.period === cohort_period.period);
                if (found) {
                  found.cumulative_time_spent_per_user = Number(cohort_period.c_time_spent);
                } else {
                  cohort_periods.push({
                    period: Number(cohort_period.period),
                    cumulative_time_spent_per_user: Number(cohort_period.c_time_spent),
                  });
                }
              });

              cohort_periods.sort((a, b) => a.period - b.period);

              cohort_periods.forEach((cohort_period, index) => {
                if (index > 0) {
                  cohort_period.cumulative_time_spent_per_user += cohort_periods[index - 1].cumulative_time_spent_per_user;
                }
              });
              cohort_periods.forEach((cohort_period, index) => {
                cohort_period.cumulative_time_spent_per_user = cohort_period.cumulative_time_spent_per_user / firstPeriod.c_distinct_users;
              });

              row[metric] = cohort_periods.find((c) => c.period === targetPeriod)?.cumulative_time_spent_per_user ?? 0;
            }
          }
          break;

        case "d0_time_spent_per_user":
        case "d1_time_spent_per_user":
        case "d7_time_spent_per_user":
          {
            if (!row.cohort_periods) {
              row.cohort_periods = [];
            }

            if (typeof element.cohort_periods === "undefined") {
              return;
            }

            const targetPeriod = Number(metric.replace("d", "").split("_")[0]);

            const cohort_periods: any = [];
            const firstPeriod = element.cohort_periods.find((c) => c.period === 0);
            if (firstPeriod) {
              element.cohort_periods.forEach((cohort_period) => {
                const found = cohort_periods.find((c) => c.period === cohort_period.period);
                if (found) {
                  found.cumulative_time_spent_per_user = Number(cohort_period.c_time_spent);
                } else {
                  cohort_periods.push({
                    period: Number(cohort_period.period),
                    cumulative_time_spent_per_user: Number(cohort_period.c_time_spent),
                  });
                }
              });

              cohort_periods.sort((a, b) => a.period - b.period);

              cohort_periods.forEach((cohort_period, index) => {
                cohort_period.cumulative_time_spent_per_user = cohort_period.cumulative_time_spent_per_user / firstPeriod.c_distinct_users;
              });

              row[metric] = cohort_periods.find((c) => c.period === targetPeriod)?.cumulative_time_spent_per_user ?? 0;
            }
          }
          break;

        case "progress_users":
          {
            if (!row.progress_list) {
              row.progress_list = [];
            }
            if (typeof element.progress_list === "undefined") {
              return;
            }

            element.progress_list.forEach((progress) => {
              const found = row.progress_list.find(
                (c) => c.progress === Number(progress.progress) && c.stage_index === Number(progress.stage_index) && c.stage_size_x === Number(progress.stage_size_x) && c.stage_size_y === Number(progress.stage_size_y),
              );
              if (found) {
                found.progress_users = Number(progress.lf_distinct_users);
              } else {
                const newRow: any = {
                  progress: Number(progress.progress),
                  stage_index: Number(progress.stage_index),
                  stage_size_x: Number(progress.stage_size_x),
                  stage_size_y: Number(progress.stage_size_y),
                  progress_users: Number(progress.lf_distinct_users),
                };
                row.progress_list.push(newRow);
              }
            });

            row.progress_list.sort((a, b) => {
              if (a.progress === b.progress) {
                return a.stage_index - b.stage_index;
              }
              return a.progress - b.progress;
            });
          }
          break;
        case "sw_progress_users":
          {
            if (!row.progress_list) {
              row.progress_list = [];
            }
            if (typeof element.progress_list === "undefined") {
              return;
            }

            element.progress_list.forEach((progress) => {
              const found = row.progress_list.find((c) => Number(c.progress) === Number(progress.progress));
              if (found) {
                found.sw_progress_users = Number(progress.lf_distinct_users);
                found.sw_level_spins = Number(progress.sw_level_spins);
                found.sw_quiz_correct = Number(progress.sw_quiz_correct);
                found.sw_quiz_correct_second_chance = Number(progress.sw_quiz_correct_second_chance);
                found.sw_quiz_wrong = Number(progress.sw_quiz_wrong);
                found.sw_quiz_wrong_second_chance = Number(progress.sw_quiz_wrong_second_chance);
                found.sw_bankrupt = Number(progress.sw_bankrupt);
                found.sw_bankrupt_second_chance = Number(progress.sw_bankrupt_second);
                found.sw_letter_guess = Number(progress.sw_letter_guess);
                found.sw_incorrect_letter_guess = Number(progress.sw_incorrect_letter_guess);
                found.sw_vowel_purchase = Number(progress.sw_vowel_purchase);
                found.sw_wrong_solve = Number(progress.sw_wrong_solve);
                found.sw_reward = Number(progress.sw_reward);
                found.sw_reward_gold = Number(progress.sw_reward_0);
                found.sw_reward_booster = Number(progress.sw_reward_1);
                found.sw_reward_vip = Number(progress.sw_reward_2);
              } else {
                const newRow: any = {
                  progress: Number(progress.progress),
                  sw_progress_users: Number(progress.lf_distinct_users),
                  sw_level_spins: Number(progress.sw_level_spins),
                  sw_quiz_correct: Number(progress.sw_quiz_correct),
                  sw_quiz_correct_second_chance: Number(progress.sw_quiz_correct_second_chance),
                  sw_quiz_wrong: Number(progress.sw_quiz_wrong),
                  sw_quiz_wrong_second_chance: Number(progress.sw_quiz_wrong_second_chance),
                  sw_bankrupt: Number(progress.sw_bankrupt),
                  sw_bankrupt_second_chance: Number(progress.sw_bankrupt_second),
                  sw_letter_guess: Number(progress.sw_letter_guess),
                  sw_incorrect_letter_guess: Number(progress.sw_incorrect_letter_guess),
                  sw_vowel_purchase: Number(progress.sw_vowel_purchase),
                  sw_wrong_solve: Number(progress.sw_wrong_solve),
                  sw_reward: Number(progress.sw_reward),
                  sw_reward_gold: Number(progress.sw_reward_0),
                  sw_reward_booster: Number(progress.sw_reward_1),
                  sw_reward_vip: Number(progress.sw_reward_2),
                };
                row.progress_list.push(newRow);
              }
            });

            row.progress_list.sort((a, b) => {
              return a.progress - b.progress;
            });
          }
          break;
        case "sw_user_churn":
          {
            if (!row.progress_list) {
              row.progress_list = [];
            }

            if (typeof element.progress_list === "undefined") {
              return;
            }

            element.progress_list.forEach((progress) => {
              const found = row.progress_list.find((c) => Number(c.progress) === Number(progress.progress));
              if (found) {
                found.sw_level_starts = Number(progress.sw_level_starts);
                found.sw_level_completes = Number(progress.sw_level_completes);
              } else {
                const newRow: any = {
                  progress: Number(progress.progress),
                  sw_level_starts: Number(progress.sw_level_starts),
                  sw_level_completes: Number(progress.sw_level_completes),
                };
                row.progress_list.push(newRow);
              }
            });

            const firstProgress = element.progress_list.find((c) => Number(c.progress) === 0);
            if (firstProgress) {
              row.progress_list.forEach((progress) => {
                progress.sw_user_churn = Number(progress.sw_level_completes) / Number(firstProgress.lf_distinct_users);
              });
            }

            row.progress_list.sort((a, b) => {
              return a.progress - b.progress;
            });
          }
          break;
        case "stage_starts":
          {
            if (!row.progress_list) {
              row.progress_list = [];
            }
            if (typeof element.progress_list === "undefined") {
              return;
            }

            element.progress_list.forEach((progress) => {
              const found = row.progress_list.find(
                (c) => c.progress === Number(progress.progress) && c.stage_index === Number(progress.stage_index) && c.stage_size_x === Number(progress.stage_size_x) && c.stage_size_y === Number(progress.stage_size_y),
              );
              if (found) {
                found.stage_starts = Number(progress.stage_starts);
              } else {
                const newRow: any = {
                  progress: Number(progress.progress),
                  stage_index: Number(progress.stage_index),
                  stage_starts: Number(progress.stage_starts),
                  stage_size_x: Number(progress.stage_size_x),
                  stage_size_y: Number(progress.stage_size_y),
                };
                row.progress_list.push(newRow);
              }
            });

            row.progress_list.sort((a, b) => {
              if (a.progress === b.progress) {
                return a.stage_index - b.stage_index;
              }
              return a.progress - b.progress;
            });
          }
          break;
        case "stage_completes":
          {
            if (!row.progress_list) {
              row.progress_list = [];
            }

            if (typeof element.progress_list === "undefined") {
              return;
            }

            element.progress_list.forEach((progress) => {
              const found = row.progress_list.find(
                (c) => c.progress === Number(progress.progress) && c.stage_index === Number(progress.stage_index) && c.stage_size_x === Number(progress.stage_size_x) && c.stage_size_y === Number(progress.stage_size_y),
              );
              if (found) {
                found.stage_completes = Number(progress.stage_completes);
              } else {
                const newRow: any = {
                  progress: Number(progress.progress),
                  stage_index: Number(progress.stage_index),
                  stage_completes: Number(progress.stage_completes),
                  stage_size_x: Number(progress.stage_size_x),
                  stage_size_y: Number(progress.stage_size_y),
                };
                row.progress_list.push(newRow);
              }
            });

            row.progress_list.sort((a, b) => {
              if (a.progress === b.progress) {
                return a.stage_index - b.stage_index;
              }
              return a.progress - b.progress;
            });
          }
          break;
        case "stage_interstitial_impressions":
          {
            if (!row.progress_list) {
              row.progress_list = [];
            }

            if (typeof element.progress_list === "undefined") {
              return;
            }

            element.progress_list.forEach((progress) => {
              const found = row.progress_list.find((c) => c.progress === Number(progress.progress));
              if (found) {
                found.stage_interstitial_impressions = Number(progress.stage_interstitial_impressions);
              } else {
                const newRow: any = {
                  progress: Number(progress.progress),
                  stage_interstitial_impressions: Number(progress.stage_interstitial_impressions),
                };
                row.progress_list.push(newRow);
              }
            });
          }
          break;
        case "stage_duration":
          {
            if (!row.progress_list) {
              row.progress_list = [];
            }

            if (typeof element.progress_list === "undefined") {
              return;
            }

            element.progress_list.forEach((progress) => {
              const found = row.progress_list.find(
                (c) => c.progress === Number(progress.progress) && c.stage_index === Number(progress.stage_index) && c.stage_size_x === Number(progress.stage_size_x) && c.stage_size_y === Number(progress.stage_size_y),
              );
              if (found) {
                found.stage_duration = Number(progress.stage_seconds_passed);
              } else {
                const newRow: any = {
                  progress: Number(progress.progress),
                  stage_index: Number(progress.stage_index),
                  stage_duration: Number(progress.stage_seconds_passed),
                  stage_size_x: Number(progress.stage_size_x),
                  stage_size_y: Number(progress.stage_size_y),
                };
                row.progress_list.push(newRow);
              }
            });

            row.progress_list.sort((a, b) => {
              if (a.progress === b.progress) {
                return a.stage_index - b.stage_index;
              }
              return a.progress - b.progress;
            });
          }
          break;
        case "cumulative_stage_duration":
          {
            if (!row.progress_list) {
              row.progress_list = [];
            }

            if (typeof element.progress_list === "undefined") {
              return;
            }

            element.progress_list.forEach((progress) => {
              const found = row.progress_list.find(
                (c) => c.progress === Number(progress.progress) && c.stage_index === Number(progress.stage_index) && c.stage_size_x === Number(progress.stage_size_x) && c.stage_size_y === Number(progress.stage_size_y),
              );
              if (found) {
                found.stage_duration = Number(progress.stage_seconds_passed);
              } else {
                const newRow: any = {
                  progress: Number(progress.progress),
                  stage_index: Number(progress.stage_index),
                  stage_duration: Number(progress.stage_seconds_passed),
                  stage_size_x: Number(progress.stage_size_x),
                  stage_size_y: Number(progress.stage_size_y),
                  progress_users: Number(progress.lf_distinct_users),
                };
                row.progress_list.push(newRow);
              }
            });

            row.progress_list.sort((a, b) => {
              if (a.progress === b.progress) {
                return a.stage_index - b.stage_index;
              }
              return a.progress - b.progress;
            });

            const firstProgress = row.progress_list.find((c) => Number(c.progress) === 0 && Number(c.stage_index) === 0);
            if (firstProgress) {
              row.progress_list.forEach((progress, index) => {
                if (index === 0) {
                  progress.cumulative_stage_duration = progress.stage_duration;
                } else {
                  progress.cumulative_stage_duration = progress.stage_duration + row.progress_list[index - 1]?.cumulative_stage_duration;
                }
              });

              row.progress_list.forEach((progress) => {
                progress.cumulative_stage_duration /= progress.progress_users;
              });
            }
          }
          break;
        case "level_churn":
          {
            if (!row.progress_list) {
              row.progress_list = [];
            }

            if (typeof element.progress_list === "undefined") {
              return;
            }

            element.progress_list.forEach((progress) => {
              const found = row.progress_list.find(
                (c) => c.progress === Number(progress.progress) && c.stage_index === Number(progress.stage_index) && c.stage_size_x === Number(progress.stage_size_x) && c.stage_size_y === Number(progress.stage_size_y),
              );
              if (found) {
                found.level_churn = Number(found.stage_completes) / Number(found.stage_starts);
              } else {
                const newRow: any = {
                  progress: Number(progress.progress),
                  stage_index: Number(progress.stage_index),
                  stage_starts: Number(progress.stage_completes),
                  stage_completes: Number(progress.stage_completes),
                  level_churn: Number(progress.stage_completes) / Number(progress.stage_starts),
                  stage_size_x: Number(progress.stage_size_x),
                  stage_size_y: Number(progress.stage_size_y),
                };
                row.progress_list.push(newRow);
              }
            });

            row.progress_list.sort((a, b) => {
              if (a.progress === b.progress) {
                return a.stage_index - b.stage_index;
              }
              return a.progress - b.progress;
            });
          }
          break;
        case "user_churn":
          {
            if (!row.progress_list) {
              row.progress_list = [];
            }

            if (typeof element.progress_list === "undefined") {
              return;
            }

            element.progress_list.forEach((progress) => {
              const found = row.progress_list.find(
                (c) => c.progress === Number(progress.progress) && c.stage_index === Number(progress.stage_index) && c.stage_size_x === Number(progress.stage_size_x) && c.stage_size_y === Number(progress.stage_size_y),
              );
              if (found) {
                found.stage_starts = Number(found.stage_starts);
                found.stage_completes = Number(found.stage_completes);
              } else {
                const newRow: any = {
                  progress: Number(progress.progress),
                  stage_index: Number(progress.stage_index),
                  stage_starts: Number(progress.stage_completes),
                  stage_completes: Number(progress.stage_completes),
                  stage_size_x: Number(progress.stage_size_x),
                  stage_size_y: Number(progress.stage_size_y),
                };
                row.progress_list.push(newRow);
              }
            });

            const firstProgress = element.progress_list.find((c) => Number(c.progress) === 0 && Number(c.stage_index) === 0);
            if (firstProgress) {
              row.progress_list.forEach((progress) => {
                progress.user_churn = progress.stage_completes / firstProgress.lf_distinct_users;
              });
            }

            row.progress_list.sort((a, b) => {
              if (a.progress === b.progress) {
                return a.stage_index - b.stage_index;
              }
              return a.progress - b.progress;
            });
          }
          break;
      }
    });

    deliverable_metrics.forEach((metric) => {
      switch (metric) {
        case "installs":
        case "spend":
        case "organic_installs":
          row[metric] = Number(element[metric]);
          break;
        case "banner_impressions":
        case "interstitial_impressions":
        case "rewarded_impressions":
        case "appopen_impressions":
          row[metric] = Number(element[`d_${metric}`]);
          break;
        case "banner_revenue":
        case "interstitial_revenue":
        case "rewarded_revenue":
        case "appopen_revenue":
          row[metric] = Number(element[`d_${metric}`]);
          break;
        case "banner_cpm":
        case "interstitial_cpm":
        case "rewarded_cpm":
        case "appopen_cpm":
          row[metric] = (Number(element[`d_${metric.split("_")[0]}_revenue`]) / Number(element[`d_${metric.split("_")[0]}_impressions`])) * 1000;
          break;
        case "ad_impressions":
          row[metric] = Number(element.d_banner_impressions) + Number(element.d_interstitial_impressions) + Number(element.d_rewarded_impressions) + Number(element.d_appopen_impressions);
          break;
        case "iap_count":
          row[metric] = Number(element.d_iap_count);
          break;
        case "banner_impressions_per_dau":
          row[metric] = Number(element.d_banner_impressions) / Number(element.d_distinct_users);
          break;
        case "interstitial_impressions_per_dau":
          row[metric] = Number(element.d_interstitial_impressions) / Number(element.d_distinct_users);
          break;
        case "appopen_impressions_per_dau":
          row[metric] = Number(element.d_appopen_impressions) / Number(element.d_distinct_users);
          break;
        case "rewarded_impressions_per_dau":
          row[metric] = Number(element.d_rewarded_impressions) / Number(element.d_distinct_users);
          break;
        case "ad_revenue":
          row[metric] = Number(element.d_banner_revenue) + Number(element.d_interstitial_revenue) + Number(element.d_rewarded_revenue) + Number(element.d_appopen_revenue);
          break;
        case "ad_arpdau":
          row[metric] = (Number(element.d_banner_revenue) + Number(element.d_interstitial_revenue) + Number(element.d_rewarded_revenue) + Number(element.d_appopen_revenue)) / Number(element.d_distinct_users);
          break;
        case "iap_arpdau":
          row[metric] = Number(element.d_iap_revenue) / Number(element.d_distinct_users);
          break;
        case "total_arpdau":
          row[metric] = (Number(element.d_banner_revenue) + Number(element.d_interstitial_revenue) + Number(element.d_rewarded_revenue) + Number(element.d_appopen_revenue) + Number(element.d_iap_revenue)) / Number(element.d_distinct_users);
          break;
        case "iap_revenue":
          row[metric] = Number(element.d_iap_revenue);
          break;
        case "total_revenue":
          row[metric] = Number(element.d_banner_revenue) + Number(element.d_interstitial_revenue) + Number(element.d_rewarded_revenue) + Number(element.d_appopen_revenue) + Number(element.d_iap_revenue);
          break;
        case "cpi":
          row[metric] = Number(element.spend) / Number(element.installs);
          break;
        case "profit":
          row[metric] = Number(element.d_banner_revenue) + Number(element.d_interstitial_revenue) + Number(element.d_rewarded_revenue) + Number(element.d_appopen_revenue) + Number(element.d_iap_revenue) - Number(element.spend);
          break;
        case "dailyActiveUsers":
          row[metric] = Number(element.d_distinct_users);
          break;
        case "time_spent_per_dau":
          row[metric] = Number(element.time_spent) / Number(element.d_distinct_users);
          break;
        case "ad_revenue_cohort":
          {
            let accumulatedRevenue = 0;
            if (typeof element.cohort_periods === "undefined") {
              return;
            }
            element.cohort_periods.forEach((cohort_period) => {
              accumulatedRevenue += cohort_period.c_banner_revenue + cohort_period.c_interstitial_revenue + cohort_period.c_rewarded_revenue + cohort_period.c_appopen_revenue;
            });
            row[metric] = accumulatedRevenue;
          }
          break;
        case "iap_revenue_cohort":
          {
            let accumulatedRevenue = 0;
            if (typeof element.cohort_periods === "undefined") {
              return;
            }
            element.cohort_periods.forEach((cohort_period) => {
              accumulatedRevenue += cohort_period.c_iap_revenue;
            });
            row[metric] = accumulatedRevenue;
          }
          break;
        case "total_revenue_cohort":
          {
            let accumulatedRevenue = 0;
            if (typeof element.cohort_periods === "undefined") {
              return;
            }
            element.cohort_periods.forEach((cohort_period) => {
              accumulatedRevenue += cohort_period.c_iap_revenue + cohort_period.c_banner_revenue + cohort_period.c_interstitial_revenue + cohort_period.c_rewarded_revenue + cohort_period.c_appopen_revenue;
            });
            row[metric] = accumulatedRevenue;
          }
          break;
        case "spend_impressions":
        case "spend_clicks":
          row[metric] = Number(element[metric]);
          break;
        case "interstitial_impressions_level_end":
        case "interstitial_impressions_sw_next_level":
        case "interstitial_impressions_on_resume":
        case "interstitial_impressions_on_resume_ao":
        case "interstitial_impressions_vip_reward":
        case "interstitial_impressions_quiz_second_chance":
        case "interstitial_impressions_bankrupt_spin_again":
        case "interstitial_impressions_quiz_level":
        case "interstitial_impressions_double_reward":
        case "interstitial_impressions_daily_level":
        case "interstitial_impressions_refill_booster":
        case "interstitial_impressions_free_spin":
        case "interstitial_impressions_rewarded_gift":
        case "interstitial_impressions_continue_with_time_bonus":
        case "interstitial_impressions_keep_streak":
        case "interstitial_impressions_next_stage":
        case "interstitial_impressions_daily_spin":
          {
            const targetInterstitial = metric.split("_");
            // Skip 0th and 1st index and concat the rest
            const targetInterstitialKey = targetInterstitial.slice(2).join("_");
            const count = Number(element[`${targetInterstitialKey}_impressions`]);
            // if (metric === "interstitial_impressions_on_resume") {
            //   count = Number(element[`${targetInterstitialKey}_impressions`]) + Number(element[`${targetInterstitialKey}_ao_impressions`]);
            // }
            if (metric === "interstitial_impressions_level_end" || metric === "interstitial_impressions_sw_next_level") {
              row[metric] = count / Number(element.all_interstitial_impressions);
            } else if (metric === "interstitial_impressions_on_resume" || metric === "interstitial_impressions_on_resume_ao") {
              row[metric] = count / (Number(element.all_interstitial_impressions) + Number(element.all_interstitial_ao_impressions));
            } else {
              row[metric] = count / Number(element.all_rewarded_impressions);
            }
          }
          break;
      }
    });

    accumulate.push(row);
  });

  if (level.split(",").includes("date") || level.split(",").includes("date_month") || level.split(",").includes("date_week")) {
    const dateField = level.split(",").find((l) => l.includes("date"));
    // Sort accumulate by 'date' field ascending using date-fns
    accumulate.sort((a, b) => {
      let aa = a[dateField];
      let bb = b[dateField];
      if (dateField === "date_week") {
        aa = a[dateField].split("-")[2];
        bb = b[dateField].split("-")[2];
        return Number(aa) - Number(bb);
      }
      return new Date(aa).getTime() - new Date(bb).getTime();
    });

    if (dateField === "date") {
      accumulate.forEach((element, index) => {
        element.maturity = dayjs().diff(dayjs(element.date, "YYYY-MM-DD"), "day");
      });
    }
  }

  // Remove all rows inside cohort_periods if period is -1
  accumulate.forEach((element) => {
    if (element.cohort_periods) {
      element.cohort_periods = element.cohort_periods.filter((c) => c.period >= 0);
    }
  });

  return accumulate;
}
