import { DeleteItemCommand, type DynamoDBClient, PutItemCommand, QueryCommand } from "@aws-sdk/client-dynamodb";

export const putProcessedFile = async (client: DynamoDBClient, data) => {
  return await client.send(
    new PutItemCommand({
      TableName: "wisdom-nw-production",
      Item: {
        pk: { S: data.date },
        sk: { S: `${data.bucket_name}#${data.file_name}` },
        file_name: { S: data.file_name },
        created_at: { S: data.created_at },
      },
    }),
  );
};

export const putSavedReport = async (client: DynamoDBClient, key, name, rid) => {
  return await client.send(
    new PutItemCommand({
      TableName: "wisdom-nw-production",
      Item: {
        pk: { S: `sr-${key}` },
        sk: { S: name },
        rid: { S: rid },
        name: { S: name },
      },
    }),
  );
};

export const removeSavedReport = async (client: DynamoDBClient, key, name) => {
  return await client.send(
    new DeleteItemCommand({
      TableName: "wisdom-nw-production",
      Key: {
        pk: { S: `sr-${key}` },
        sk: { S: name },
      },
    }),
  );
};

export const getProcessedFiles = async (client: DynamoDBClient, date, bucket_name) => {
  const response = await client.send(
    new QueryCommand({
      TableName: "wisdom-nw-production",
      KeyConditionExpression: "pk = :pk AND begins_with(sk, :sk)",
      ExpressionAttributeValues: {
        ":pk": { S: date },
        ":sk": { S: bucket_name },
      },
    }),
  );

  if (response.Items) {
    const keys: any = [];
    response.Items.forEach((object) => {
      keys.push(object.file_name.S);
    });
    return keys;
  }

  return [];
};

export const getSavedReports = async (client: DynamoDBClient, key) => {
  const response = await client.send(
    new QueryCommand({
      TableName: "wisdom-nw-production",
      KeyConditionExpression: "pk = :pk",
      ExpressionAttributeValues: {
        ":pk": { S: `sr-${key}` },
      },
    }),
  );

  if (response.Items) {
    const keys: any = [];
    response.Items.forEach((object) => {
      keys.push({ rid: object.rid.S, name: object.name.S });
    });
    return keys;
  }

  return [];
};

export const getRid = async (client: DynamoDBClient, rid) => {
  const response = await client.send(
    new QueryCommand({
      TableName: "wisdom-nw-production",
      KeyConditionExpression: "pk = :pk",
      ExpressionAttributeValues: {
        ":pk": { S: `rid-${rid}` },
      },
    }),
  );

  if (response.Items) {
    const item: any = response.Items[0];
    return { rid: rid, data: JSON.parse(item.data.S) };
  }

  return null;
};

export const putRid = async (client: DynamoDBClient, rid, data) => {
  return await client.send(
    new PutItemCommand({
      TableName: "wisdom-nw-production",
      Item: {
        pk: { S: `rid-${rid}` },
        sk: { S: rid.toString() },
        data: { S: JSON.stringify(data) },
      },
    }),
  );
};
