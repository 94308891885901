import express, { type Request, type Response, type Router } from "express";

import { DynamoDBClient } from "@aws-sdk/client-dynamodb";
import { createClient } from "@clickhouse/client";
import { format } from "date-fns";
import { ServiceResponse } from "../../common/models/serviceResponse";
import { processReport } from "../../common/report/process";
import type { FilterDef } from "../../common/report/types";
import { getRid, getSavedReports, putRid, putSavedReport, removeSavedReport } from "../../common/utils/ddb";
import { handleServiceResponse } from "../../common/utils/httpHandlers";
import { murmurHash32 } from "../../common/utils/murmur";

export const reportRouter: Router = express.Router();

const clickhouseClient = createClient({
  url: process.env.CLICKHOUSE_HOST ?? "http://localhost:8123",
  username: process.env.CLICKHOUSE_USER ?? "default",
  password: process.env.CLICKHOUSE_PASSWORD ?? "",
  request_timeout: 30000 * 1000,
});

const ddbClient = new DynamoDBClient();

reportRouter.get("/health", async (req: Request, res: Response) => {
  const serviceResponse = ServiceResponse.success("we're good", null);
  return handleServiceResponse(serviceResponse, res);
});
reportRouter.post("/get_reports", async (req: Request, res: Response) => {
  const key = req.body.key;
  const reportRids: any = await getSavedReports(ddbClient, key);
  const serviceResponse = ServiceResponse.success(reportRids, null);
  return handleServiceResponse(serviceResponse, res);
});
reportRouter.post("/report_save", async (req: Request, res: Response) => {
  const name = req.body.name;
  const rid = req.body.rid;
  const key = req.body.key;
  await putSavedReport(ddbClient, key, name, rid);
  const serviceResponse = ServiceResponse.success("we're good", null);
  return handleServiceResponse(serviceResponse, res);
});

reportRouter.post("/report_delete", async (req: Request, res: Response) => {
  const name = req.body.name;
  const key = req.body.key;
  await removeSavedReport(ddbClient, key, name);
  const serviceResponse = ServiceResponse.success("we're good", null);
  return handleServiceResponse(serviceResponse, res);
});

reportRouter.post("/", async (req: Request, res: Response) => {
  let date_start = new Date(req.body.date_start);
  let date_end = new Date(req.body.date_end);
  let game_id = req.body.game_id;
  let filterDefs: FilterDef[] = req.body.filters;
  let level = req.body.level;
  let filters = { filters: filterDefs };
  let deliverable_metrics = req.body.deliverable_metrics;
  let cohort_metrics = req.body.cohort_metrics;
  let metric_dimensions = req.body.metrics_dimensions;
  let date_preset = req.body.date_preset;
  let metadata = req.body.metadata;
  const rid = req.body.rid;

  let newRid = 0;
  let ridData: any = null;
  if (typeof req.body.rid !== "undefined") {
    const fetchedRidData = await getRid(ddbClient, rid);
    if (fetchedRidData) {
      ridData = fetchedRidData.data;
      newRid = rid;
      date_start = ridData.date_start;
      date_end = ridData.date_end;
      game_id = ridData.game_id;
      filterDefs = ridData.filters;
      level = ridData.level;
      filters = { filters: filterDefs };
      deliverable_metrics = ridData.deliverable_metrics;
      cohort_metrics = ridData.cohort_metrics;
      metric_dimensions = ridData.metrics_dimensions;
      date_preset = ridData.date_preset;
      metadata = ridData.metadata;
    }
  } else {
    newRid = murmurHash32(new Date().getTime().toString());
    ridData = {
      game_id,
      date_start: format(date_start, "yyyy-MM-dd"),
      date_end: format(date_end, "yyyy-MM-dd"),
      filters: filters.filters,
      level,
      deliverable_metrics,
      cohort_metrics,
      metrics_dimensions: metric_dimensions,
      date_preset: date_preset,
      metadata: metadata,
    };
    await putRid(ddbClient, newRid, ridData);
  }

  const result = await processReport(clickhouseClient, game_id, date_start, date_end, date_preset, filters, level, deliverable_metrics, cohort_metrics, metric_dimensions, newRid);
  const response: any = { rid: newRid, result: result, request: ridData };
  const serviceResponse = ServiceResponse.success(response, null);

  return handleServiceResponse(serviceResponse, res);
});
