export const adjust = `
insert into adjust_raw
SELECT
    splitByChar('T', splitByChar('_', _file, 2)[2])[1] as date,
    if(\`{app_token}\` == 'abj63g0tqq68', 'wt', 'unknown_game_id') as game_id,
    \`{activity_kind}\` as activity_kind,
    toString(\`{app_version}\`) as app_version,
    splitByString('::', coalesce(\`{tracker_name}\`, ''))[1] as network_name,
    splitByString('::', coalesce(\`{tracker_name}\`, ''))[2] as campaign_name,
    splitByString('::', coalesce(\`{tracker_name}\`, ''))[3] as adgroup_name,
    splitByString('::', coalesce(\`{tracker_name}\`, ''))[4] as creative_name,
    \`{os_name}\` as platform,
    \`{os_version}\` as platform_version,
    \`{device_type}\` as device_type,
    \`{language}\` as language,
    \`{connection_type}\` as connection_type,
    \`{country}\` as country,
    \`{event_name}\` as event_name,
    \`{adid}\` as user_id,
    \`{idfa}\` as idfa,
    \`{gps_adid}\` as gps_adid,
    \`{idfv}\` as idfv,
    toFloat64OrZero(toString(\`{last_time_spent}\`)) as last_time_spent,
    \`{ip_address}\` as ip_address,
    \`{cost_amount}\` as cost_amount,
    toInt64OrZero(toString(\`{click_time}\`)) as click_time,
    toInt64OrZero(toString(\`{impression_time}\`)) as impression_time,
    toInt64OrZero(toString(\`{created_at}\`)) as created_at,
    toInt64OrZero(toString(\`{installed_at}\`)) as installed_at,
    \`{ad_revenue_unit}\` as ad_revenue_unit,
    \`{ad_revenue_network}\` as ad_revenue_network,
    \`{ad_revenue_placement}\` as ad_revenue_placement,
    toFloat64OrZero(toString(\`{revenue_usd}\`)) as revenue,
    \`[nct_user_id]\` as nct_user_id,
    \`[nct_profile]\` as nct_profile,
    toFloat64OrZero(toString(\`[latency]\`)) as latency,
    toFloat64OrZero(toString(\`{time_spent}\`)) as time_spent
FROM s3('https://s3.us-east-1.amazonaws.com/<REPLACE_ME>', 'CSVWithNames', extra_credentials(role_arn = 'arn:aws:iam::446841157439:role/ClickHouseAccessRole-Cloud'))
settings input_format_csv_use_best_effort_in_schema_inference = false;
`;
export const spend = `
insert into spend
SELECT
    toDate(spend_date),
    spend_hour,
    network_name,
    country,
    campaign_name,
    adgroup_name,
    creative_name,
    campaign_id,
    adgroup_id,
    creative_id,
    spend,
    impressions,
    clicks,
    installs,
    spend_date as report_date,
    splitByString('=', splitByString('/',_path)[7])[2] as game_id,
    splitByString('=', splitByString('/',_path)[8])[2] as platform
FROM s3('https://s3.us-east-1.amazonaws.com/<REPLACE_ME>', 'Parquet', extra_credentials(role_arn = 'arn:aws:iam::446841157439:role/ClickHouseAccessRole-Cloud'));
`;

export const game_data = `
insert into game_data
SELECT
    splitByString('=', splitByString('/',_path)[5])[2] as ingestion_date,
    splitByString('=', splitByString('/',_path)[6])[2] as game_id,
    splitByString('=', splitByString('/',_path)[7])[2] as platform,
    \`country\`,
    \`created_at\` as created_at,
    \`meta\`.first_open_timestamp as installed_at,
    \`user_id\` as user_id,
    toString(\`meta\`.build_version) as build_version,
    \`meta\`.profile_name as profile_name,
    \`id\` as event_id,
    \`name\` as event_name,
    \`data\` as event_data
FROM s3('https://s3.us-east-1.amazonaws.com/<REPLACE_ME>', 'Parquet', extra_credentials(role_arn = 'arn:aws:iam::446841157439:role/ClickHouseAccessRole-Cloud'))
where \`name\` not in ('ad_loaded', 'ad_load', 'ad_load_failed', 'ad_clicked', 'foregrounded', 'sdk_init', 'log', 'missed_opportunity', 'joined_league', 'level_exit', 'level_play_on', 'ad_show_attempt_failed');
`;
