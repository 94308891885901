import { format } from "date-fns";
import { pbcopy } from "../utils/pbcopy";
import { CohortMetrics, DeliverableGameData, DeliverableMetrics, type Filters, LevelMetrics, removeLevelFunnelDimensions, spendMetrics } from "./types";

function createFilterQuery(query_type, filters) {
  let filtersQuery = "";
  filters.forEach((element) => {
    let multiOp = "in";
    let singleOp = "=";
    let skip = false;
    if (typeof element.operator !== "undefined") {
      switch (element.operator) {
        case "not":
          multiOp = "not in";
          singleOp = "!=";
          break;
        default:
          break;
      }
    }
    if (element.field === "date") {
      switch (query_type) {
        case "spend":
          filtersQuery += `report_date between '${element.valueBetweenStart}' and '${element.valueBetweenEnd}'`;
          break;
        case "cohort":
          filtersQuery += `formatDateTime(fromUnixTimestamp(installed_at), '%F') between '${element.valueBetweenStart}' and '${element.valueBetweenEnd}'`;
          break;
        case "level_funnel":
          filtersQuery += `formatDateTime(fromUnixTimestamp(toInt64(installed_at/1000)), '%F') between '${element.valueBetweenStart}' and '${element.valueBetweenEnd}'`;
          break;
        case "deliverable":
          filtersQuery += `formatDateTime(fromUnixTimestamp(created_at), '%F') between '${element.valueBetweenStart}' and '${element.valueBetweenEnd}'`;
          break;
        case "deliverable_game_data":
          filtersQuery += `formatDateTime(fromUnixTimestamp(toInt64(created_at/1000)), '%F') between '${element.valueBetweenStart}' and '${element.valueBetweenEnd}'`;
          break;
      }
    }
    if (element.field === "country") {
      if (element.value.split(",").length > 1) {
        filtersQuery += `lower(country) in (${element.value
          .split(",")
          .map((value) => `'${value}'`)
          .join(",")})`;
      } else {
        filtersQuery += `lower(country) = '${element.value}'`;
      }
    }
    if (element.field === "app_version") {
      let tableField = element.field;
      if (query_type === "level_funnel") {
        tableField = "build_version";
      }
      if (element.value.split(",").length > 1) {
        filtersQuery += `${tableField} in (${element.value
          .split(",")
          .map((value) => `'${value}'`)
          .join(",")})`;
      } else {
        filtersQuery += `${tableField} = '${element.value}'`;
      }
    }

    if (element.field === "platform") {
      if (element.value.split(",").length > 1) {
        filtersQuery += `platform in (${element.value
          .split(",")
          .map((value) => `'${value}'`)
          .join(",")})`;
      } else {
        filtersQuery += `platform = '${element.value}'`;
      }
    }

    if (element.field === "game_id") {
      if (element.value.split(",").length > 1) {
        filtersQuery += `game_id in (${element.value
          .split(",")
          .map((value) => `'${value}'`)
          .join(",")})`;
      } else {
        filtersQuery += `game_id = '${element.value}'`;
      }
    }

    if (element.field === "creative_name") {
      if (element.value.split(",").length > 1) {
        filtersQuery += `if(network_name = 'applovin', replaceAll(replaceAll(replaceAll(replaceAll(creative_name, 'Video_Landscape_Long_3PPlayableEndcard_', ''), 'Video_Portrait_Long_3PPlayableEndcard_', ''), '3PPlayable_', ''), '3PRewardedPlayable_', ''), creative_name)  in (${element.value
          .split(",")
          .map((value) => `'${value}'`)
          .join(",")})`;
      } else {
        filtersQuery += `if(network_name = 'applovin', replaceAll(replaceAll(replaceAll(replaceAll(creative_name, 'Video_Landscape_Long_3PPlayableEndcard_', ''), 'Video_Portrait_Long_3PPlayableEndcard_', ''), '3PPlayable_', ''), '3PRewardedPlayable_', ''), creative_name)  = '${element.value}'`;
      }
    }

    if (element.field === "campaign_name") {
      if (element.value.split(",").length > 1) {
        filtersQuery += `trimBoth(replaceRegexpOne(campaign_name, '\\\(\\S+\\\)', ''))  in (${element.value
          .split(",")
          .map((value) => `'${value}'`)
          .join(",")})`;
      } else {
        filtersQuery += `trimBoth(replaceRegexpOne(campaign_name, '\\\(\\S+\\\)', ''))  = '${element.value}'`;
      }
    }

    if (element.field === "network_name") {
      const fixNetworkName = (network_name) => {
        let fixed = network_name;
        switch (query_type) {
          case "deliverable":
          case "deliverable_game_data":
          case "cohort":
            if (network_name === "unity_ads" || network_name === "unity") {
              fixed = "Unity Ads";
            }
            if (network_name === "organic") {
              fixed = "Organic";
            }
            break;
          case "spend":
            if (network_name === "unity_ads") {
              fixed = "unity";
            }
            break;
        }
        return fixed;
      };
      if (element.value.split(",").length > 1) {
        const items = element.value.split(",");
        if (items.includes("organic")) {
          items.splice(items.indexOf("organic"), 1);
        }

        filtersQuery += `network_name ${multiOp} (${items.map((value) => `'${fixNetworkName(value)}'`).join(",")})`;
      } else {
        if (query_type === "spend" && element.value === "organic") {
          skip = true;
        } else {
          filtersQuery += `network_name ${singleOp} '${fixNetworkName(element.value)}'`;
        }
      }
    }

    if (element.field === "periodLessEquals") {
      filtersQuery += `floor((created_at - installed_at) / (86400*1000)) <= ${element.value}`;
    }

    if (!skip) {
      filtersQuery += " AND\n";
    }
  });

  filtersQuery = filtersQuery.slice(0, -4);
  return filtersQuery;
}

export function computeLevel(query_type, inputLevel, metric_dimensions) {
  const levels = inputLevel.split(",");
  // Remove '' from levels
  const emptyIndex = levels.indexOf("");
  if (emptyIndex > -1) {
    levels.splice(emptyIndex, 1);
  }
  const processedMetricDimensions: any = [...metric_dimensions];
  switch (query_type) {
    case "deliverable":
    case "deliverable_game_data":
    case "spend":
    case "level_funnel":
      {
        // Remove 'period' from metric dimensions
        let periodIndex = processedMetricDimensions.indexOf("period");
        if (periodIndex > -1) {
          processedMetricDimensions.splice(periodIndex, 1);
        }

        // Remove 'period' from inputLevel
        periodIndex = levels.indexOf("period");
        if (periodIndex > -1) {
          levels.splice(periodIndex, 1);
        }
      }
      break;
  }
  if (processedMetricDimensions.length > 0) {
    levels.push(...processedMetricDimensions);
  }

  return levels;
}

function createDimensionsQuery(query_type, level, metric_dimensions) {
  let dimensionsQuery = "";

  const levels = computeLevel(query_type, level, metric_dimensions);

  levels.forEach((element) => {
    let added = false;
    switch (element) {
      case "game_id":
      case "platform":
      case "adgroup_name":
        dimensionsQuery += element;
        added = true;
        break;
      case "ad_revenue_network":
        if (query_type === "deliverable" || query_type === "cohort") {
          dimensionsQuery += "if(ad_revenue_network in ('AppLovin', 'APPLOVIN_EXCHANGE'), 'AppLovin', ad_revenue_network) as ad_revenue_network";
          added = true;
        }
        break;

      case "app_version":
        if (query_type === "level_funnel") {
          dimensionsQuery += "build_version as app_version";
        } else {
          dimensionsQuery += element;
        }
        added = true;
        break;
      case "network_name":
        if (query_type === "deliverable" || query_type === "cohort") {
          dimensionsQuery += "if(network_name = 'Unity Ads', 'unity', network_name) as network_name";
        } else {
          dimensionsQuery += element;
        }
        added = true;
        break;
      case "campaign_name":
        if (query_type === "deliverable" || query_type === "cohort") {
          dimensionsQuery += `trimBoth(replaceRegexpOne(campaign_name, '\\\(\\S+\\\)', '')) as campaign_name`;
        } else {
          dimensionsQuery += element;
        }
        added = true;
        break;
      case "country":
        dimensionsQuery += "lower(country) as country";
        added = true;
        break;
      case "creative_name":
        if (query_type === "deliverable" || query_type === "cohort" || query_type === "level_funnel") {
          dimensionsQuery +=
            "if(network_name = 'applovin', replaceAll(replaceAll(replaceAll(replaceAll(creative_name, 'Video_Landscape_Long_3PPlayableEndcard_', ''), 'Video_Portrait_Long_3PPlayableEndcard_', ''), '3PPlayable_', ''), '3PRewardedPlayable_', ''), creative_name) as creative_name";
        } else {
          dimensionsQuery += element;
        }
        added = true;
        break;
      case "date":
        switch (query_type) {
          case "deliverable":
            dimensionsQuery += "formatDateTime(fromUnixTimestamp(created_at), '%F') as date";
            added = true;
            break;
          case "cohort":
            dimensionsQuery += "formatDateTime(fromUnixTimestamp(installed_at), '%F') as date";
            added = true;
            break;
          case "spend":
            dimensionsQuery += "report_date as date";
            added = true;
            break;
          case "level_funnel":
            dimensionsQuery += "formatDateTime(fromUnixTimestamp(toInt64(installed_at/1000)), '%F') as date";
            added = true;
            break;
          case "deliverable_game_data":
            dimensionsQuery += "formatDateTime(fromUnixTimestamp(toInt64(created_at/1000)), '%F') as date";
            added = true;
            break;
        }
        break;
      case "date_month":
        switch (query_type) {
          case "deliverable":
            dimensionsQuery += "formatDateTime(fromUnixTimestamp(created_at), '%Y-%m') as date_month";
            added = true;
            break;
          case "cohort":
            dimensionsQuery += "formatDateTime(fromUnixTimestamp(installed_at), '%Y-%m') as date_month";
            added = true;
            break;
          case "spend":
            dimensionsQuery += "formatDateTime(toDate(report_date), '%Y-%m') as date_month";
            added = true;
            break;
          case "level_funnel":
            dimensionsQuery += "formatDateTime(fromUnixTimestamp(toInt64(installed_at/1000)), '%Y-%m') as date";
            break;
          case "deliverable_game_data":
            dimensionsQuery += "formatDateTime(fromUnixTimestamp(toInt64(created_at/1000)), '%Y-%m') as date";
            break;
        }
        break;
      case "date_week":
        switch (query_type) {
          case "deliverable":
            dimensionsQuery += "formatDateTime(fromUnixTimestamp(created_at), '%Y-%m-%V') as date_week";
            added = true;
            break;
          case "cohort":
            dimensionsQuery += "formatDateTime(fromUnixTimestamp(installed_at), '%Y-%m-%V') as date_week";
            added = true;
            break;
          case "spend":
            dimensionsQuery += "formatDateTime(toDate(report_date), '%Y-%m-%V') as date_week";
            added = true;
            break;
          case "level_funnel":
            break;
          case "deliverable_game_data":
            dimensionsQuery += "formatDateTime(fromUnixTimestamp(toInt64(created_at/1000)), '%Y-%m-%V') as date_week";
            added = true;
            break;
        }
        break;
      case "period":
        dimensionsQuery += "floor((created_at - installed_at) / 86400) as period";
        added = true;
        break;
      case "progress":
      case "sw_progress":
        dimensionsQuery += `toInt64(event_data['${element}'].2) as progress`;
        added = true;
        break;
      case "stage_index":
      case "stage_size_x":
      case "stage_size_y":
        dimensionsQuery += `toInt64(event_data['${element}'].2) as ${element}`;
        added = true;
        break;
    }

    if (added) {
      dimensionsQuery += ",\n";
    }
  });

  if (query_type === "level_funnel") {
    // metric_dimensions.forEach((element) => {
    //   dimensionsQuery += `toInt64(event_data['${element}'].2) as ${element},\n`;
    // });
    // dimensionsQuery = dimensionsQuery.slice(0, -2);
  } else {
  }
  dimensionsQuery = dimensionsQuery.slice(0, -2);
  return dimensionsQuery;
}

export async function generateReport(clickhouse, game_id, date_start, date_end, filters: Filters, level, deliverable_metrics, cohort_metrics, metric_dimensions) {
  let hasDeliverableMetrics = false;
  let hasCohortMetrics = false;
  let hasSpendMetrics = false;
  let hasLevelMetrics = false;
  let hasDeliverableGameDataMetrics = false;

  Object.keys(DeliverableMetrics).forEach((metric) => {
    if (deliverable_metrics.includes(DeliverableMetrics[metric])) {
      hasDeliverableMetrics = true;
    }
  });

  Object.keys(CohortMetrics).forEach((metric) => {
    if (cohort_metrics.includes(CohortMetrics[metric])) {
      hasCohortMetrics = true;
    }
    // if (deliverable_metrics.includes(CohortMetrics[metric])) {
    //   hasCohortMetrics = true;
    // }
  });
  Object.keys(LevelMetrics).forEach((metric) => {
    if (cohort_metrics.includes(LevelMetrics[metric])) {
      hasLevelMetrics = true;
    }
  });

  Object.keys(DeliverableGameData).forEach((metric) => {
    if (deliverable_metrics.includes(DeliverableGameData[metric])) {
      hasDeliverableGameDataMetrics = true;
    }
  });

  deliverable_metrics.forEach((metric) => {
    if (metric.endsWith("_cohort")) {
      hasCohortMetrics = true;
    }
  });

  deliverable_metrics.forEach((metric) => {
    if (spendMetrics.includes(metric)) {
      hasSpendMetrics = true;
    }
  });
  cohort_metrics.forEach((metric) => {
    if (spendMetrics.includes(metric)) {
      hasSpendMetrics = true;
    }
  });

  // if (cohort_metrics.length > 0) {
  //   const computedLevel = computeLevel("cohort", level, metric_dimensions);
  //   if (!computedLevel.includes("period") && !metric_dimensions.includes("period")) {
  //     metric_dimensions.push("period");
  //   }
  // }

  console.log(`report gen: deliverable: ${hasDeliverableMetrics} cohort: ${hasCohortMetrics} spend: ${hasSpendMetrics} level: ${hasLevelMetrics} deliverableGameData: ${hasDeliverableGameDataMetrics}`);

  const metrics: any = [];
  const datasets: any = [];
  if (hasDeliverableMetrics) {
    const deliverableReportData = await generateDeliverableReport(clickhouse, game_id, date_start, date_end, filters, level, metric_dimensions);
    datasets.push(deliverableReportData);
    metrics.push({ name: "deliverable", count: deliverableReportData.length });
  }

  if (hasCohortMetrics) {
    const cohortReportData = await genereateCohortReport(clickhouse, game_id, date_start, date_end, filters, level, metric_dimensions);
    datasets.push(cohortReportData);
    metrics.push({ name: "cohort", count: cohortReportData.length });
  }

  if (hasSpendMetrics) {
    const spendReportData = await generateSpendReport(clickhouse, game_id, date_start, date_end, filters, level, metric_dimensions);
    datasets.push(spendReportData);
    metrics.push({ name: "spend", count: spendReportData.length });
  }

  if (hasLevelMetrics) {
    const levelReportData = await generateLevelFunnelReport(clickhouse, game_id, date_start, date_end, filters, level, metric_dimensions);
    datasets.push(levelReportData);
    metrics.push({ name: "level_funnel", count: levelReportData.length });
  }

  if (hasDeliverableGameDataMetrics) {
    const levelReportData = await generateDeliverableGameDataReport(clickhouse, game_id, date_start, date_end, filters, level, metric_dimensions);
    datasets.push(levelReportData);
    metrics.push({ name: "deliverable_game_data", count: levelReportData.length });
  }

  return mergeReports(level, metric_dimensions, datasets);
}

async function generateSpendReport(clickhouse, game_id, date_start, date_end, filters: Filters, level, metric_dimensions) {
  const dimensionsQuery = createDimensionsQuery("spend", level, metric_dimensions);
  const levels = computeLevel("spend", level, metric_dimensions);

  const metricsQuery = `
      SUM(spend) as spend,
      SUM(impressions) as spend_impressions,
      SUM(clicks) as spend_clicks
    `;

  let groupByQuery = "";
  levels.forEach((_element, index) => {
    groupByQuery += `${index + 1},`;
  });
  groupByQuery = groupByQuery.slice(0, -1);

  const filtersQuery = createFilterQuery("spend", filters.filters);
  let query = "select ";
  if (dimensionsQuery.length > 0) {
    query += `${dimensionsQuery},`;
  }

  query = `
      ${query}
      ${metricsQuery}
      from default.spend
      `;

  if (filtersQuery.length > 0) {
    query += ` where ${filtersQuery}`;
  }

  if (groupByQuery.length > 0) {
    query += ` group by ${groupByQuery}`;
  }
  console.log(query);
  const resultSet = await clickhouse.query({
    query: query,
    format: "JSONEachRow",
  });
  return await resultSet.json();
}

async function genereateCohortReport(clickhouse, game_id, date_start, date_end, filters: Filters, level, metric_dimensions) {
  //   const spendReportData = await generateSpendReport(clickhouse, game_id, date_start, date_end, filters, level);
  const dimensionsQuery = createDimensionsQuery("cohort", level, metric_dimensions);
  const levels = computeLevel("cohort", level, metric_dimensions);

  const metricsQuery = `
    count(distinct user_id) as c_distinct_users,
    sum(if(activity_kind == 'ad_revenue' and (ad_revenue_unit == 'e9db2cfa005db490' or ad_revenue_unit == '25938e97b5c35a2e'),1, 0 )) as c_banner_impressions,
    sum(if(activity_kind == 'ad_revenue' and (ad_revenue_unit == 'e9db2cfa005db490' or ad_revenue_unit == '25938e97b5c35a2e'),revenue, 0 )) as c_banner_revenue,
       
    sum(if(activity_kind == 'ad_revenue' and (ad_revenue_unit == '09854fbc569b14b6' or ad_revenue_unit == 'ecb1624458a8ce4f'),1, 0 )) as c_interstitial_impressions,
    sum(if(activity_kind == 'ad_revenue' and (ad_revenue_unit == '09854fbc569b14b6' or ad_revenue_unit == 'ecb1624458a8ce4f'),revenue, 0 )) as c_interstitial_revenue,

    sum(if(activity_kind == 'ad_revenue' and (ad_revenue_unit == '566e4a1bd0283848' or ad_revenue_unit == '43cb9cf7e3ca64b5'),1, 0 )) as c_rewarded_impressions,
    sum(if(activity_kind == 'ad_revenue' and (ad_revenue_unit == '566e4a1bd0283848' or ad_revenue_unit == '43cb9cf7e3ca64b5'),revenue, 0 )) as c_rewarded_revenue,

    sum(if(activity_kind == 'ad_revenue' and (ad_revenue_unit == '28e263430aa8ee75' or ad_revenue_unit == 'd53274119c5c6721'),1, 0 )) as c_appopen_impressions,
    sum(if(activity_kind == 'ad_revenue' and (ad_revenue_unit == '28e263430aa8ee75' or ad_revenue_unit == 'd53274119c5c6721'),revenue, 0 )) as c_appopen_revenue,
       
    sum(if(event_name == 'in_app_purchase', revenue, 0)) as c_iap_revenue,
    sum(last_time_spent) as c_time_spent
  `;

  let groupByQuery = "";
  levels.forEach((_element, index) => {
    groupByQuery += `${index + 1},`;
  });
  groupByQuery = groupByQuery.slice(0, -1);

  const filtersQuery = createFilterQuery("cohort", filters.filters);

  let query = "select ";
  if (dimensionsQuery.length > 0) {
    query += `${dimensionsQuery},`;
  }

  query = `
    ${query}
    ${metricsQuery}
    from adjust_raw
        where
        adjust_raw.date between '${format(date_start, "yyyy-MM-dd")}' and '${format(date_end, "yyyy-MM-dd")}'
        and activity_kind in ('ad_revenue', 'install', 'event', 'session')
    `;
  if (filtersQuery.length > 0) {
    query += ` and ${filtersQuery}`;
  }
  if (groupByQuery.length > 0) {
    query += ` group by ${groupByQuery}`;
  }

  console.log(query);

  try {
    const resultSet = await clickhouse.query({
      query: query,
      format: "JSONEachRow",
    });
    return await resultSet.json();
  } catch (error) {
    console.log(query);
    throw new Error(`Error generating cohort report: ${error}`);
  }
}

async function generateDeliverableReport(clickhouse, game_id, date_start, date_end, filters: Filters, level, metric_dimensions) {
  const dimensionsQuery = createDimensionsQuery("deliverable", level, metric_dimensions);

  const metricsQuery = `
    count(distinct if(activity_kind == 'install',user_id, null)) as installs,
    count(distinct if(activity_kind == 'install' and network_name in ('organic', 'Organic'),user_id, null)) as organic_installs,
    count(distinct user_id) as d_distinct_users,
    sum(if(activity_kind == 'ad_revenue' and (ad_revenue_unit == 'e9db2cfa005db490' or ad_revenue_unit == '25938e97b5c35a2e'),1, 0 )) as d_banner_impressions,
    sum(if(activity_kind == 'ad_revenue' and (ad_revenue_unit == 'e9db2cfa005db490' or ad_revenue_unit == '25938e97b5c35a2e'),revenue, 0 )) as d_banner_revenue,
       
    sum(if(activity_kind == 'ad_revenue' and (ad_revenue_unit == '09854fbc569b14b6' or ad_revenue_unit == 'ecb1624458a8ce4f'),1, 0 )) as d_interstitial_impressions,
    sum(if(activity_kind == 'ad_revenue' and (ad_revenue_unit == '09854fbc569b14b6' or ad_revenue_unit == 'ecb1624458a8ce4f'),revenue, 0 )) as d_interstitial_revenue,

    sum(if(activity_kind == 'ad_revenue' and (ad_revenue_unit == '566e4a1bd0283848' or ad_revenue_unit == '43cb9cf7e3ca64b5'),1, 0 )) as d_rewarded_impressions,
    sum(if(activity_kind == 'ad_revenue' and (ad_revenue_unit == '566e4a1bd0283848' or ad_revenue_unit == '43cb9cf7e3ca64b5'),revenue, 0 )) as d_rewarded_revenue,

    sum(if(activity_kind == 'ad_revenue' and (ad_revenue_unit == '28e263430aa8ee75' or ad_revenue_unit == 'd53274119c5c6721'),1, 0 )) as d_appopen_impressions,
    sum(if(activity_kind == 'ad_revenue' and (ad_revenue_unit == '28e263430aa8ee75' or ad_revenue_unit == 'd53274119c5c6721'),revenue, 0 )) as d_appopen_revenue,
       
    sum(if(event_name == 'in_app_purchase', revenue, 0)) as d_iap_revenue,
    sum(if(event_name == 'in_app_purchase', 1, 0)) as d_iap_count,
    sum(last_time_spent) as time_spent
  `;

  let groupByQuery = "";
  computeLevel("deliverable", level, metric_dimensions).forEach((_element, index) => {
    groupByQuery += `${index + 1},`;
  });
  groupByQuery = groupByQuery.slice(0, -1);

  const filtersQuery = createFilterQuery("deliverable", filters.filters);

  let query = "select ";
  if (dimensionsQuery.length > 0) {
    query += `${dimensionsQuery},`;
  }

  query = `
    ${query}
    ${metricsQuery}
    from adjust_raw
        where
        adjust_raw.date between '${format(date_start, "yyyy-MM-dd")}' and '${format(date_end, "yyyy-MM-dd")}'
        and activity_kind in ('ad_revenue', 'install', 'event', 'session')
    `;

  if (filtersQuery.length > 0) {
    query += ` and ${filtersQuery}`;
  }

  if (groupByQuery.length > 0) {
    query += ` group by ${groupByQuery}`;
  }

  try {
    const resultSet = await clickhouse.query({
      query: query,
      format: "JSONEachRow",
    });
    return await resultSet.json();
  } catch (error) {
    console.log(query);
    throw new Error(`Error generating deliverable report: ${error}`);
  }
}

async function generateLevelFunnelReport(clickhouse, game_id, date_start, date_end, filters: Filters, level, metric_dimensions) {
  const levels = level.split(",");
  metric_dimensions.forEach((element) => {
    levels.push(element);
  });
  const dimensionsQuery = createDimensionsQuery("level_funnel", level, metric_dimensions);

  let metricsQuery = `
    count(distinct user_id) as lf_distinct_users,
    count(distinct if(event_name = 'stage_start', event_id, null)) as stage_starts,
    count(distinct if(event_name = 'stage_completed', event_id, null)) as stage_completes,
    sum(if(event_name = 'stage_completed', toInt64(event_data['stage_seconds_passed'].2), 0)) as stage_seconds_passed
  `;

  if (levels.includes("sw_progress")) {
    metricsQuery = `
    count(distinct user_id) as lf_distinct_users,
    count(distinct if(event_name = 'sw_level_start', event_id, null)) as sw_level_starts,
    count(distinct if(event_name = 'sw_level_completed', event_id, null)) as sw_level_completes,
    sum(if(event_name = 'sw_level_completed', toInt64(event_data['sw_level_spins'].2), 0)) as sw_level_spins,
    count(distinct if(event_name = 'sw_quiz_correct' and toInt64(event_data['second_chance'].2) = 0, event_id, null)) as sw_quiz_correct,
    count(distinct if(event_name = 'sw_quiz_correct' and toInt64(event_data['second_chance'].2) = 1, event_id, null)) as sw_quiz_correct_second_chance,
    count(distinct if(event_name = 'sw_quiz_wrong' and toInt64(event_data['second_chance'].2) = 0, event_id, null)) as sw_quiz_wrong,
    count(distinct if(event_name = 'sw_quiz_wrong' and toInt64(event_data['second_chance'].2) = 1, event_id, null)) as sw_quiz_wrong_second_chance,
    count(distinct if(event_name = 'sw_bankrupt', event_id, null)) as sw_bankrupt,
    count(distinct if(event_name = 'sw_bankrupt_second', event_id, null)) as sw_bankrupt_second,
    count(distinct if(event_name = 'sw_letter_guess', event_id, null)) as sw_letter_guess,
    count(distinct if(event_name = 'sw_letter_guess' and toInt64(event_data['open_count'].2) = 0, event_id, null)) as sw_incorrect_letter_guess,
    count(distinct if(event_name = 'sw_vowel_purchase', event_id, null)) as sw_vowel_purchase,
    count(distinct if(event_name = 'sw_solve_wrong', event_id, null)) as sw_wrong_solve,
    count(distinct if(event_name = 'sw_reward', event_id, null)) as sw_reward,
    count(distinct if(event_name = 'sw_reward' and toInt64(event_data['reward_id'].2) = 0, event_id, null)) as sw_reward_0,
    count(distinct if(event_name = 'sw_reward' and toInt64(event_data['reward_id'].2) = 1, event_id, null)) as sw_reward_1,
    count(distinct if(event_name = 'sw_reward' and toInt64(event_data['reward_id'].2) = 2, event_id, null)) as sw_reward_2
  `;
  }

  let groupByQuery = "";
  let counter = 0;
  levels.forEach((_element, index) => {
    if (_element !== "") {
      groupByQuery += `${counter + 1},`;
      counter++;
    }
  });
  groupByQuery = groupByQuery.slice(0, -1);

  let filtersQuery = createFilterQuery("level_funnel", filters.filters);
  if (levels.includes("sw_progress")) {
    filtersQuery +=
      "and toInt64(event_data['sw_progress'].2) is not null and event_name in ('sw_level_start', 'sw_level_completed', 'sw_quiz_correct', 'sw_quiz_wrong', 'sw_bankrupt', 'sw_bankrupt_second', 'sw_letter_guess', 'sw_vowel_purchase', 'sw_solve_wrong', 'sw_reward')";
  } else {
    filtersQuery += "and event_name in ('stage_start', 'stage_completed') and toInt64(event_data['is_quiz'].2) = 0";
  }

  let query = "select ";
  if (dimensionsQuery.length > 0) {
    query += `${dimensionsQuery},`;
  }

  query = `
    ${query}
    ${metricsQuery}
    from game_data 
    where
        ingestion_date between '${format(date_start, "yyyy-MM-dd")}' and '${format(date_end, "yyyy-MM-dd")}'
    `;
  if (filtersQuery.length > 0) {
    query += ` and ${filtersQuery} and profile_name != 'default'`;
  }
  if (groupByQuery.length > 0) {
    query += ` group by ${groupByQuery}`;
  }

  console.log(query);

  try {
    const resultSet = await clickhouse.query({
      query: query,
      format: "JSONEachRow",
    });
    return await resultSet.json();
  } catch (error) {
    console.log(query);
    throw new Error(`Error generating level report: ${error}`);
  }
}

async function generateDeliverableGameDataReport(clickhouse, game_id, date_start, date_end, filters: Filters, level, metric_dimensions) {
  const levels = level.split(",");
  metric_dimensions.forEach((element) => {
    levels.push(element);
  });
  const dimensionsQuery = createDimensionsQuery("deliverable_game_data", level, metric_dimensions);

  const metricsQuery = `
    sum(if(event_name = 'stage_completed', toInt64(event_data['stage_seconds_passed'].2), 0)) as stage_seconds_passed,
    count(distinct if(event_data['attr'].1 = 'level_end', event_id, null)) as level_end_impressions,
count(distinct if(event_data['attr'].1 = 'sw_next_level', event_id, null)) as sw_next_level_impressions,
count(distinct if(event_data['attr'].1 = 'on_resume', event_id, null)) as on_resume_impressions,
count(distinct if(event_data['attr'].1 = 'on_resume_ao', event_id, null)) as on_resume_ao_impressions,
count(distinct if(event_data['attr'].1 = 'vip_reward', event_id, null)) as vip_reward_impressions,
count(distinct if(event_data['attr'].1 = 'quiz_second_chance', event_id, null)) as quiz_second_chance_impressions,
count(distinct if(event_data['attr'].1 = 'bankrupt_spin_again', event_id, null)) as bankrupt_spin_again_impressions,
count(distinct if(event_data['attr'].1 = 'quiz_level', event_id, null)) as quiz_level_impressions,
count(distinct if(event_data['attr'].1 = 'double_reward', event_id, null)) as double_reward_impressions,
count(distinct if(event_data['attr'].1 = 'daily_level', event_id, null)) as daily_level_impressions,
count(distinct if(event_data['attr'].1 = 'refill_booster', event_id, null)) as refill_booster_impressions,
count(distinct if(event_data['attr'].1 = 'free_spin', event_id, null)) as free_spin_impressions,
count(distinct if(event_data['attr'].1 = 'rewarded_gift', event_id, null)) as rewarded_gift_impressions,
count(distinct if(event_data['attr'].1 = 'continue_with_time_bonus', event_id, null)) as continue_with_time_bonus_impressions,
count(distinct if(event_data['attr'].1 = 'keep_streak', event_id, null)) as keep_streak_impressions,
count(distinct if(event_data['attr'].1 = 'next_stage', event_id, null)) as next_stage_impressions,
count(distinct if(event_data['attr'].1 = 'daily_spin', event_id, null)) as daily_spin_impressions,
count(distinct if(event_data['at'].2 = 1, event_id, null)) as all_interstitial_impressions,
count(distinct if(event_data['at'].2 = 2, event_id, null)) as all_rewarded_impressions,
count(distinct if(event_data['at'].2 = 3, event_id, null)) as all_interstitial_ao_impressions
  `;

  let groupByQuery = "";
  let counter = 0;
  levels.forEach((_element, index) => {
    if (_element !== "") {
      groupByQuery += `${counter + 1},`;
      counter++;
    }
  });
  groupByQuery = groupByQuery.slice(0, -1);

  let filtersQuery = createFilterQuery("deliverable_game_data", filters.filters);
  filtersQuery += "and event_name in ('ad_impression')";

  let query = "select ";
  if (dimensionsQuery.length > 0) {
    query += `${dimensionsQuery},`;
  }

  query = `
    ${query}
    ${metricsQuery}
    from game_data gd
        where
        gd.ingestion_date between '${format(date_start, "yyyy-MM-dd")}' and '${format(date_end, "yyyy-MM-dd")}'
    `;
  if (filtersQuery.length > 0) {
    query += ` and ${filtersQuery} and gd.profile_name != 'default'`;
  }
  if (groupByQuery.length > 0) {
    query += ` group by ${groupByQuery}`;
  }

  query += " settings max_execution_time=60";

  console.log(query);

  try {
    const resultSet = await clickhouse.query({
      query: query,
      format: "JSONEachRow",
    });
    return await resultSet.json();
  } catch (error) {
    console.log(query);
    throw new Error(`Error generating level report: ${error}`);
  }
}

function mergeReports(level, metricDimensions, datasets) {
  const accumulate = {};
  datasets.forEach((dataset) => {
    // Cohort report
    if (dataset.length > 0 && typeof dataset[0].period !== "undefined") {
      const mergePoints = computeLevel("deliverable", level, metricDimensions);
      dataset.forEach((row) => {
        let mergeHash = "";
        mergePoints.forEach((point) => {
          if (row[point]) {
            mergeHash += `${row[point]}_`;
          } else {
            mergeHash += "_null_";
          }
        });
        mergeHash = mergeHash.slice(0, -1);
        if (!accumulate[mergeHash]) {
          accumulate[mergeHash] = [];
        }
        accumulate[mergeHash].push({ period: row.period, row: row });
      });
      return;
    }
    // Level Funnel
    if (dataset.length > 0 && typeof dataset[0].progress !== "undefined") {
      const mergePoints = removeLevelFunnelDimensions(computeLevel("level_funnel", level, metricDimensions));

      dataset.forEach((row) => {
        let mergeHash = "";
        mergePoints.forEach((point) => {
          if (row[point]) {
            mergeHash += `${row[point]}_`;
          } else {
            mergeHash += "_null_";
          }
        });
        mergeHash = mergeHash.slice(0, -1);
        if (!accumulate[mergeHash]) {
          accumulate[mergeHash] = [];
        }
        const preMerge = { row: row };
        // for future me: I know you won't understand this, you gotta figure it out again
        computeLevel("level_funnel", level, metricDimensions).forEach((point) => {
          let p = point;
          if (p === "sw_progress") {
            p = "progress";
          }
          preMerge[p] = row[p];
        });
        accumulate[mergeHash].push(preMerge);
      });
      return;
    }
    const mergePoints = computeLevel("deliverable", level, metricDimensions);
    dataset.forEach((row) => {
      let mergeHash = "";
      mergePoints.forEach((point) => {
        if (row[point]) {
          mergeHash += `${row[point]}_`;
        } else {
          mergeHash += "_null_";
        }
      });
      mergeHash = mergeHash.slice(0, -1);
      if (!accumulate[mergeHash]) {
        accumulate[mergeHash] = [];
      }
      accumulate[mergeHash].push(row);
    });
  });

  const mergedData: any = [];
  Object.keys(accumulate).forEach((key) => {
    const merged: any = {};
    accumulate[key].forEach((row) => {
      if (typeof row.period !== "undefined") {
        if (!merged.cohort_periods) {
          merged.cohort_periods = [];
        }
        merged.cohort_periods.push(row.row);
        Object.keys(row.row).forEach((key) => {
          if (computeLevel("deliverable", level, metricDimensions).includes(key)) {
            merged[key] = row.row[key];
            return;
          }
        });
        return;
      } else if (typeof row.progress !== "undefined") {
        if (!merged.progress_list) {
          merged.progress_list = [];
        }
        merged.progress_list.push(row.row);
        Object.keys(row.row).forEach((key) => {
          if (removeLevelFunnelDimensions(computeLevel("level_funnel", level, metricDimensions)).includes(key)) {
            merged[key] = row.row[key];
            return;
          }
        });
        return;
      } else {
        Object.keys(row).forEach((key) => {
          if (computeLevel("deliverable", level, metricDimensions).includes(key)) {
            merged[key] = row[key];
            return;
          }
          merged[key] = row[key];
        });
      }
    });
    mergedData.push(merged);
  });
  return mergedData;
}
