import express, { type Request, type Response, type Router } from "express";

import { DynamoDBClient } from "@aws-sdk/client-dynamodb";
import { S3Client } from "@aws-sdk/client-s3";
import { createClient } from "@clickhouse/client";
import { routine } from "../../common/ingestion/routine";
import { ServiceResponse } from "../../common/models/serviceResponse";
import { handleServiceResponse } from "../../common/utils/httpHandlers";

export const ingestionRouter: Router = express.Router();

const clickhouseClient = createClient({
  url: process.env.CLICKHOUSE_HOST ?? "http://localhost:8123",
  username: process.env.CLICKHOUSE_USER ?? "default",
  password: process.env.CLICKHOUSE_PASSWORD ?? "",
  request_timeout: 900 * 1000,
});

const s3Client = new S3Client();
const ddbClient = new DynamoDBClient();

ingestionRouter.post("/check", async (req: Request, res: Response) => {
  const dryRun = req.body.dry_run;
  const targetDate = req.body.date;
  const targetBucket = req.body.bucket;

  const results = await routine(s3Client, ddbClient, clickhouseClient, targetDate, targetBucket, dryRun);
  const serviceResponse = ServiceResponse.success(results, null);

  return handleServiceResponse(serviceResponse, res);
});
