export enum DeliverableMetrics {
  Installs = "installs",
  Spend = "spend",
  DailyActiveUsers = "dailyActiveUsers",
  AdRevenue = "ad_revenue",
  IAPRevenue = "iap_revenue",
  TotalRevenue = "total_revenue",
  CPI = "cpi",
  CohortAdRevenue = "ad_revenue_cohort",
  CohortIAPRevenue = "iap_revenue_cohort",
  CohortTotalRevenue = "total_revenue_cohort",
  Profit = "profit",
  AdARPDAU = "ad_arpdau",
  IAPARPDAU = "iap_arpdau",
  TotalARPDAU = "total_arpdau",
  TimeSpentPerDAU = "time_spent_per_dau",
  BannerCPM = "banner_cpm",
  InterstitialCPM = "interstitial_cpm",
  RewardedCPM = "rewarded_cpm",
  AppOpenCPM = "appopen_cpm",
  IAPCount = "iap_count",
  BannerImpressionsPerDAU = "banner_impressions_per_dau",
  InterstitialImpressionsPerDAU = "interstitial_impressions_per_dau",
  RewardedImpressionsPerDAU = "rewarded_impressions_per_dau",
  AppOpenImpressionsPerDAU = "appopen_impressions_per_dau",
  BannerImpressions = "banner_impressions",
  InterstitialImpressions = "interstitial_impressions",
  RewardedImpressions = "rewarded_impressions",
  AppOpenImpressions = "appopen_impressions",
  BannerRevenue = "banner_revenue",
  InterstitialRevenue = "interstitial_revenue",
  RewardedRevenue = "rewarded_revenue",
  AppOpenRevenue = "appopen_revenue",
}

export enum CohortMetrics {
  RetainedUsers = "retained_users",
  AdRevenue = "ad_revenue",
  IAPRevenue = "iap_revenue",
  TotalRevenue = "total_revenue",
  CumulativeAdRevenue = "cumulative_ad_revenue",
  CumulativeIAPRevenue = "cumulative_iap_revenue",
  CumulativeTotalRevenue = "cumulative_total_revenue",
  Retention = "retention",
  AdRoas = "ad_roas",
  IAPRoas = "iap_roas",
  TotalRoas = "total_roas",
  TimeSpentPerUser = "time_spent_per_user",
  CumulativeTimeSpentPerUser = "cumulative_time_spent_per_user",

  // Standalone Time Spent
  D0CumulativeTimeSpentPerUser = "d0_cumulative_time_spent_per_user",
  D1CumulativeTimeSpentPerUser = "d1_cumulative_time_spent_per_user",
  D7CumulativeTimeSpentPerUser = "d7_cumulative_time_spent_per_user",

  D0TimeSpentPerUser = "d0_time_spent_per_user",
  D1TimeSpentPerUser = "d1_time_spent_per_user",
  D7TimeSpentPerUser = "d7_time_spent_per_user",

  // Standalone ROAS metrics
  D0Roas = "d0_roas",
  D1Roas = "d1_roas",
  D2Roas = "d2_roas",
  D3Roas = "d3_roas",
  D4Roas = "d4_roas",
  D5Roas = "d5_roas",
  D6Roas = "d6_roas",
  D7Roas = "d7_roas",
  D14Roas = "d14_roas",
  D21Roas = "d21_roas",
  D27Roas = "d27_roas",
  D30Roas = "d30_roas",
  D60Roas = "d60_roas",
  D90Roas = "d90_roas",
  CurrentRoas = "current_roas",
  // Standalone Retention
  D0Retention = "d0_retention",
  D1Retention = "d1_retention",
  D2Retention = "d2_retention",
  D3Retention = "d3_retention",
  D4Retention = "d4_retention",
  D5Retention = "d5_retention",
  D6Retention = "d6_retention",
  D7Retention = "d7_retention",
  D14Retention = "d14_retention",
  D21Retention = "d21_retention",
  D27Retention = "d27_retention",
  D30Retention = "d30_retention",
  D60Retention = "d60_retention",
  D90Retention = "d90_retention",
  D120Retention = "d120_retention",
  D150Retention = "d150_retention",
  D180Retention = "d180_retention",
  D210Retention = "d210_retention",
  D240Retention = "d240_retention",
  D270Retention = "d270_retention",
  D300Retention = "d300_retention",
  D330Retention = "d330_retention",
  D360Retention = "d360_retention",

  // Standalone Multipliers
  RoasMult7_0 = "roas_mult_7_0",
  RoasMult30_0 = "roas_mult_30_0",
  RoasMult60_0 = "roas_mult_60_0",
  RoasMult90_0 = "roas_mult_90_0",
  RoasMult30_7 = "roas_mult_30_7",
  RoasMult60_7 = "roas_mult_60_7",
  RoasMult90_7 = "roas_mult_90_7",
  RoasMult60_30 = "roas_mult_60_30",
  RoasMult90_30 = "roas_mult_90_30",
  RoasMult90_60 = "roas_mult_90_60",
  RoasMult120_30 = "roas_mult_120_30",
  RoasMult120_60 = "roas_mult_120_60",
  RoasMult120_90 = "roas_mult_120_90",
  RoasMult150_120 = "roas_mult_150_120",
  RoasMult180_150 = "roas_mult_180_150",
  RoasMult210_180 = "roas_mult_210_180",
  RoasMult240_210 = "roas_mult_240_210",
  RoasMult270_240 = "roas_mult_270_240",
  RoasMult300_270 = "roas_mult_300_270",
  RoasMult330_300 = "roas_mult_330_300",
  RoasMult360_330 = "roas_mult_360_330",
  RoasMult360_180 = "roas_mult_360_180",
  RoasMult360_30 = "roas_mult_360_30",
  RoasMultX_0 = "roas_mult_x_0",

  D0BannerImpressionsPerUser = "d0_banner_impressions_per_user",
  D1BannerImpressionsPerUser = "d1_banner_impressions_per_user",
  D7BannerImpressionsPerUser = "d7_banner_impressions_per_user",
  D0InterstitialImpressionsPerUser = "d0_interstitial_impressions_per_user",
  D1InterstitialImpressionsPerUser = "d1_interstitial_impressions_per_user",
  D7InterstitialImpressionsPerUser = "d7_interstitial_impressions_per_user",
  D0RewardedImpressionsPerUser = "d0_rewarded_impressions_per_user",
  D1RewardedImpressionsPerUser = "d1_rewarded_impressions_per_user",
  D7RewardedImpressionsPerUser = "d7_rewarded_impressions_per_user",

  D0BannerCPM = "d0_banner_cpm",
  D1BannerCPM = "d1_banner_cpm",
  D7BannerCPM = "d7_banner_cpm",
  D0InterstitialCPM = "d0_interstitial_cpm",
  D1InterstitialCPM = "d1_interstitial_cpm",
  D7InterstitialCPM = "d7_interstitial_cpm",
  D0RewardedCPM = "d0_rewarded_cpm",
  D1RewardedCPM = "d1_rewarded_cpm",
  D7RewardedCPM = "d7_rewarded_cpm",
}

export enum LevelMetrics {
  Users = "progress_users",
  SWUsers = "sw_progress_users",
  StageStarts = "stage_starts",
  StageCompletes = "stage_completes",
  LevelChurn = "level_churn",
  UserChurn = "user_churn",
  SWUserChurn = "sw_user_churn",
  StageDuration = "stage_duration",
}

export enum DeliverableGameData {
  InterImpressionsLevelEnd = "interstitial_impressions_level_end",
  InterImpressionsSWNextLevel = "interstitial_impressions_sw_next_level",
  InterImpressionsOnResume = "interstitial_impressions_on_resume",
  InterImpressionsOnResumeAO = "interstitial_impressions_on_resume_ao",
  InterImpressionsVipReward = "interstitial_impressions_vip_reward",
  InterImpressionsQuizSecondChance = "interstitial_impressions_quiz_second_chance",
  InterImpressionsBankruptSpinAgain = "interstitial_impressions_bankrupt_spin_again",
  InterImpressionsQuizLevel = "interstitial_impressions_quiz_level",
  InterImpressionsDoubleReward = "interstitial_impressions_double_reward",
  InterImpressionsDailyLevel = "interstitial_impressions_daily_level",
  InterImpressionsRefillBooster = "interstitial_impressions_refill_booster",
  InterImpressionsFreeSpin = "interstitial_impressions_free_spin",
  InterImpressionsRewardedGift = "interstitial_impressions_rewarded_gift",
  InterImpressionsContinueWithTimeBonus = "interstitial_impressions_continue_with_time_bonus",
  InterImpressionsKeepStreak = "interstitial_impressions_keep_streak",
  InterImpressionsNextStage = "interstitial_impressions_next_stage",
}

export interface FilterDef {
  field: string;
  value: string;
  valueBetweenStart: string;
  valueBetweenEnd: string;
}

export interface Filters {
  filters: FilterDef[];
}

export const spendMetrics = [
  "spend",
  "cpi",
  "ad_roas",
  "iap_roas",
  "total_roas",
  "spend_impressions",
  "spend_clicks",
  "profit",
  "d0_roas",
  "d1_roas",
  "d2_roas",
  "d3_roas",
  "d4_roas",
  "d5_roas",
  "d6_roas",
  "d7_roas",
  "d14_roas",
  "d21_roas",
  "d27_roas",
  "d30_roas",
  "d60_roas",
  "d90_roas",
  "roas_mult_7_0",
  "roas_mult_30_0",
  "roas_mult_60_0",
  "roas_mult_90_0",
  "roas_mult_30_7",
  "roas_mult_60_7",
  "roas_mult_90_7",
  "roas_mult_60_30",
  "roas_mult_90_30",
  "roas_mult_90_60",
  "roas_mult_x_0",
];

export const removeLevelFunnelDimensions = (level) => {
  if (level.indexOf("progress") > -1) {
    level.splice(level.indexOf("progress"), 1);
  }
  if (level.indexOf("sw_progress") > -1) {
    level.splice(level.indexOf("sw_progress"), 1);
  }
  if (level.indexOf("stage_index") > -1) {
    level.splice(level.indexOf("stage_index"), 1);
  }
  if (level.indexOf("stage_size_x") > -1) {
    level.splice(level.indexOf("stage_size_x"), 1);
  }
  if (level.indexOf("stage_size_y") > -1) {
    level.splice(level.indexOf("stage_size_y"), 1);
  }
  return level;
};
