import { CopyObjectCommand, ListObjectsV2Command, type S3Client } from "@aws-sdk/client-s3";

export const listObjects = async (bucket_name, prefix, client: S3Client) => {
  console.log(`Listing objects in bucket: ${bucket_name} with prefix: ${prefix}`);
  const response = await client.send(
    new ListObjectsV2Command({
      Bucket: bucket_name,
      Prefix: prefix,
    }),
  );
  const keys: any = [];
  response.Contents?.forEach((object) => {
    keys.push(object.Key);
  });

  return keys;
};

export const copyObject = async (sourceBucket, sourceKey, destinationBucket, destinationKey, client: S3Client) => {
  console.log(`Copying object from ${sourceBucket}/${sourceKey} to ${destinationBucket}/${destinationKey}`);
  return await client.send(
    new CopyObjectCommand({
      Bucket: destinationBucket,
      CopySource: `${sourceBucket}/${sourceKey}`,
      Key: destinationKey,
    }),
  );
};
