import cors from "cors";
import express, { type Express } from "express";
import helmet from "helmet";
import { pino } from "pino";

import { ingestionRouter } from "../src/api/ingestion/router";
import { reportRouter } from "../src/api/report/router";
import errorHandler from "../src/common/middleware/errorHandler";
import requestLogger from "../src/common/middleware/requestLogger";
import { env } from "../src/common/utils/envConfig";

const logger = pino({ name: "server start" });
const app: Express = express();

// Set the application to trust the reverse proxy
app.set("trust proxy", true);

// Middlewares
app.use(cors({ origin: process.env.CORS_ORIGIN }));
app.use(helmet());
// app.use(rateLimiter);
// Request logging
app.use(requestLogger);
app.use(express.json());

// Routes
app.use("/report", reportRouter);
app.use("/ingestion", ingestionRouter);

// Error handlers
app.use(errorHandler());

export { app, logger };
